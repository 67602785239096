import { useState } from 'react';
import { Stack, FormControl, FormLabel, Input, Textarea, Box, Button } from "@mui/joy";
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../authContext';
import Search from "../../Search";
import ExistingReferencesTabs from "../ExistingReferencesTabs";

function CreateConnection({ 
  mode = "default",
  setCreateConnectionModal, 
  handleCreateConnection,
  comment,
  setComment,
  source,
  setSource,
  sourceTitle,
  setSourceTitle,
  target,
  setTarget,
  targetTitle,
  setTargetTitle,
  existingReferences,
}) {

  console.log("existingReferences", existingReferences)

  return (
    mode === "default" ? (
      <form
        onSubmit={(event) => {
          event.preventDefault();
          setCreateConnectionModal(false);
      }}
      >
        <Stack spacing={2}>
          <FormControl>
            <FormLabel>Source</FormLabel>
            {/* <Input defaultValue={source} onChange={(e) => setSource(e.target.value)} size="sm" autoFocus required /> */}
            <Search mode="addlink" setValue={setSource} setLinkTitle={setSourceTitle} />
          </FormControl>
          <FormControl>
            <FormLabel>Link to</FormLabel>
            <Search mode="addlink" setValue={setTarget} setLinkTitle={setTargetTitle} />
            {/* <Input defaultValue={target} onChange={(e) => setTarget(e.target.value)} size="sm" required /> */}
          </FormControl>
          <FormControl>
            <FormLabel>Comment (optional)</FormLabel>
            <Textarea defaultValue={comment} onChange={(e) => setComment(e.target.value)} size="sm" minRows={3} />
          </FormControl>
          <Box>
            <Button onClick={handleCreateConnection} type="submit">Create</Button>
          </Box>
        </Stack>
      </form>
    ) : (
      <form
        onSubmit={(event) => {
          event.preventDefault();
      }}
      >
        <Stack spacing={2}>
          <FormControl>
            <FormLabel>Link to</FormLabel>
            <Search mode="addlink" setValue={setTarget} setLinkTitle={setTargetTitle} />
            {/* <Input defaultValue={target} onChange={(e) => setTarget(e.target.value)} size="sm" required /> */}
          </FormControl>
          {/* Add list of existing connections if connections.length > 0 */}
          {existingReferences.length > 0 && (
            <ExistingReferencesTabs 
              existingReferences={existingReferences}
            />
          )}
          {target !== "" && (
            <FormControl>
              <FormLabel>Comment (optional)</FormLabel>
              <Textarea defaultValue={comment} onChange={(e) => setComment(e.target.value)} size="sm" minRows={3} />
            </FormControl>
          )}
        </Stack>
      </form>
    )
  )
}

export default CreateConnection;