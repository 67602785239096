import { useEffect, useState } from 'react';
import { Grid, Typography, Button } from '@mui/joy';
import { getSpreadsheet, getSpreadsheetRows, getSpreadsheetProcessing } from '../../../../database/spreadsheets-db';
import SpreadsheetRowCard from './SpreadsheetRowCard';
import { useParams } from 'react-router-dom';
import InlineLoader from '../../../Loaders/InlineLoader';
import { deleteSpreadsheet, updateSpreadsheetHeaders, updateSpreadsheetRow, updateSpreadsheetDisplayHeader } from '../../../../database/spreadsheets-db';
import { useMultiTasking } from '../../../../contexts/multiTaskingContext';
import DisplayHeaderSelector from './DisplayHeaderSelector';
import FeedbackSnackbar from '../../../Snackbar';
import { useNotes } from '../../../../contexts/notesContext';
import { deleteNote } from '../../../../database/notes-db';
import InfiniteScroll from 'react-infinite-scroll-component';

const Spreadsheet = ({ content }) => {

  const [spreadsheet, setSpreadsheet] = useState(null);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMoreRows, setHasMoreRows] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { selectedNote } = useNotes();

  const { accountId } = useParams();
  const { panels, updatePanel } = useMultiTasking();

  // Snackbar user feedback states
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('neutral')
  const [snackbarMsg, setSnackbarMsg] = useState('')

  const handleFetchRows = async () => {
    if (isLoadingMore) return;
    
    try {
      setIsLoadingMore(true);
      const { rows: fetchedRows, lastDoc: newLastDoc } = await getSpreadsheetRows(accountId, content?.id, 20, lastDoc);
      
      setRows(prevRows => [...prevRows, ...fetchedRows]);
      setLastDoc(newLastDoc);
      setHasMoreRows(fetchedRows.length === 20);
    } catch (error) {
      console.error("Error fetching rows", error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const handleInitialFetch = async () => {
    try {
      setIsLoading(true);
      setRows([]);
      setLastDoc(null);
      const { rows: fetchedRows, lastDoc: newLastDoc } = await getSpreadsheetRows(accountId, content?.id, 20);
      
      setRows(fetchedRows);
      setLastDoc(newLastDoc);
      setHasMoreRows(fetchedRows.length === 20);
    } catch (error) {
      console.error("Error fetching initial rows", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisplayHeaderUpdate = (rowId, newHeaderId) => {
    setRows(prevRows => 
      prevRows.map(row => 
        row.id === rowId 
          ? { ...row, displayHeaderId: newHeaderId }
          : row
      )
    );
  };

  const handleFetchSpreadsheet = async () => {
    try {
      setIsLoading(true);
      let fetchedSpreadsheet = await getSpreadsheet(accountId, content?.id);
      
      if (!fetchedSpreadsheet) {
        let isPolling = true;
        let pollCount = 0;
        const MAX_POLLS = 7; // 30 seconds maximum (7 polls * 4 seconds interval ~ 30 seconds)
        const POLL_INTERVAL = 4000; // 4 seconds

        // Poll for processing status
        const checkProcessing = async () => {
          if (!isPolling || pollCount >= MAX_POLLS) {
            if (pollCount >= MAX_POLLS) {
              setSpreadsheet(null);
            }
            return;
          }

          pollCount++;
          const results = await getSpreadsheetProcessing(accountId, content?.id);

          if (results[0].status === 'failed') {
            isPolling = false;
            console.log("spreadsheet processing failed");
            await deleteNote(content?.id, accountId);
            setSnackbarSeverity('error');
            setSnackbarMsg('Spreadsheet processing issue. Spreadsheet deleted. Please retry, checking the header row index.');
            setShowSnackbar(true);
            setSpreadsheet(null);
            return;
          }
          
          if (results[0].status === 'completed') {
            isPolling = false;
            fetchedSpreadsheet = await getSpreadsheet(accountId, content?.id);
            setSpreadsheet(fetchedSpreadsheet || null);
          } else if (isPolling) {
            setTimeout(checkProcessing, POLL_INTERVAL);
          }
        };
        
        checkProcessing();
        
        // Cleanup function
        return () => {
          isPolling = false;
        };
      } else {
        setSpreadsheet(fetchedSpreadsheet);
      }
    } catch (error) {
      console.error("Error fetching spreadsheet", error);
      setSpreadsheet(null);
    } finally {
      setIsLoading(false);
    }
  }

  const handleHeaderUpdate = async (headerId, newLabel) => {
    try {
      if (!spreadsheet?.headers) return;
      
      const updatedHeaders = spreadsheet.headers.map(header => 
        header.id === headerId ? { ...header, label: newLabel } : header
      );
      
      await updateSpreadsheetHeaders(accountId, content.id, updatedHeaders);
      
      setSpreadsheet(prev => ({
        ...prev,
        headers: updatedHeaders
      }));

      // Update panel with both new headers and current row
      const existingPanel = panels.find(panel => panel.type === 'spreadsheet_row');
      if (existingPanel) {
        const currentRow = rows.find(r => r.id === existingPanel.panelId);
        updatePanel(existingPanel.panelId, {
          ...existingPanel,
          content: {
            ...existingPanel.content,
            headers: updatedHeaders,
            row: currentRow
          }
        });
      }
    } catch (error) {
      console.error("Error updating header:", error);
    }
  };

  const handleValueUpdate = async (rowId, headerId, newValue) => {
    try {
      const rowToUpdate = rows.find(r => r.id === rowId);
      if (!rowToUpdate) return;

      const updatedRow = {
        ...rowToUpdate,
        cells: rowToUpdate.cells.map(cell => 
          cell.headerId === headerId ? { ...cell, value: newValue } : cell
        )
      };

      await updateSpreadsheetRow(accountId, content.id, rowId, updatedRow);
      
      setRows(prevRows => 
        prevRows.map(row => row.id === rowId ? updatedRow : row)
      );

      // Update panel with current headers and new row
      const existingPanel = panels.find(panel => panel.type === 'spreadsheet_row');
      if (existingPanel && existingPanel.panelId === rowId) {
        updatePanel(existingPanel.panelId, {
          ...existingPanel,
          content: {
            ...existingPanel.content,
            row: updatedRow,
            headers: spreadsheet.headers
          }
        });
      }
    } catch (error) {
      console.error("Error updating value:", error);
    }
  };

  const handleDisplayHeaderChange = async (newHeaderId) => {
    try {
      await updateSpreadsheetDisplayHeader(accountId, content.id, newHeaderId);
      // Update local state
      setRows(prevRows => 
        prevRows.map(row => ({
          ...row,
          displayHeaderId: newHeaderId
        }))
      );
    } catch (error) {
      console.error("Error updating display header:", error);
    }
  };

  useEffect(() => {
    // fetch the spreadsheet
    if (content?.id) {
      handleFetchSpreadsheet();
    }
  }, [content])

  useEffect(() => {
    if (!spreadsheet && content?.id) {
      handleInitialFetch();
    }
  }, [spreadsheet])

  if (isLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center" sx={{ p: 2, minHeight: 200 }}>
        <InlineLoader loading={isLoading} width={50} height={50} />
      </Grid>
    );
  }

  if (!spreadsheet || !rows.length) {
    return (
      <Grid container justifyContent="center" alignItems="center" sx={{ p: 2, minHeight: 200 }}>
        <p>No data available</p>
      </Grid>
    );
  }

  return (
    <Grid 
      container 
      spacing={2} 
      sx={{ 
        p: 2,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Grid item xs={12}>
        <Typography level="title-lg">
          {selectedNote?.noteTitle}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DisplayHeaderSelector 
          headers={spreadsheet?.headers}
          currentDisplayHeaderId={rows[0]?.displayHeaderId}
          onHeaderChange={handleDisplayHeaderChange}
        />
      </Grid>
      <Grid 
        item 
        xs={12} 
        sx={{ 
          flex: 1,
          overflow: 'auto',
          minHeight: 0
        }}
      >
        <Grid container spacing={2}>
          {rows.map(row => (
            <Grid key={row?.id} item xs={12} md={6} lg={4}>
              <SpreadsheetRowCard 
                row={row} 
                headers={spreadsheet?.headers}
                handleHeaderUpdate={handleHeaderUpdate}
                handleValueUpdate={handleValueUpdate}
              />
            </Grid>
          ))}
        </Grid>
        
        {/* Load More Button Section */}
        {hasMoreRows && (
          <Grid container justifyContent="center" py={2}>
            <Button
              variant="plain"
              loading={isLoadingMore}
              disabled={isLoadingMore}
              onClick={handleFetchRows}
              sx={{ my: 2 }}
              size="sm"
            >
              {isLoadingMore ? 'Loading...' : 'Load More Rows'}
            </Button>
          </Grid>
        )}
        
        {/* End Message */}
        {!hasMoreRows && rows.length > 0 && (
          <Grid container justifyContent="center" py={2}>
            <Typography level="body-sm" color="neutral">
              All rows loaded
            </Typography>
          </Grid>
        )}
      </Grid>
      <FeedbackSnackbar 
        openSetting={showSnackbar}
        colorSetting={snackbarSeverity}
        message={snackbarMsg}
        setShowSnackbar={setShowSnackbar}
      />
    </Grid>
  );
};

export default Spreadsheet;