import React from "react";
import { Box, Stack } from "@mui/joy";
import { getFileAttributes } from "../../../../utils/utils";
import Pdf from '../Pdf';
import ViewPrivateItemInput from "../../../ViewPrivateItemInput";
import { useNotes } from "../../../../contexts/notesContext";
import NotesPaneHeaderView from "../../NotesPaneHeaderView";
import VideoTranscripts from "../../../VideoTranscripts";
import Spreadsheet from "../Spreadsheet";

const UploadElement = ({ content, mode = "view" }) => {

  const videoRef = React.useRef(null);

  return (() => {
    const fileType = getFileAttributes(content.url);
    switch (fileType) {
      case 'spreadsheet':
        return <Spreadsheet content={content} />;        
      case 'image':
        return (
          <Box sx={{ 
            borderRadius: 10, 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            minHeight: 0,
            height: 'auto'
          }}>
            <img
              srcSet={`${content.url}`}
              src={`${content.url}`}
              alt={content.noteTitle}
              style={{ 
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain',
                height: 'auto'
              }}
            />
          </Box>
        );
      case 'video':
        return (
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              minHeight: 0,
              px: 2,
              py: 2,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <Box sx={{ 
              height: '100%', 
              flex: 3, 
              display: 'flex', 
              justifyContent: 'flex-start', 
              alignItems: 'flex-start',
              flexDirection: 'column'
            }}>
              <video 
                src={`${content.url}`} 
                controls 
                ref={videoRef} 
                style={{ 
                  width: '100%',
                  height: 'auto'
                }} 
              />
            </Box>
            <Box sx={{ flex: 2 }}>
              {/* Video Transcripts */}
              <VideoTranscripts videoRef={videoRef} content={content} />
            </Box>
          </Box>
        );
      case 'pdf':
        return <Pdf content={content} mode={mode} />;
      default:
        return <p>Can't preview this file type</p>;
    }
  })()
}

function Uploads({ content, mode = "view" }) {
  const { passedPasswordCheck } = useNotes();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Stack 
        spacing={2} 
        sx={{ 
          flex: 1,
          overflowY: 'auto',
          width: '100%'
        }}
      >
        {content.private ? (
          !passedPasswordCheck ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <ViewPrivateItemInput content={content} />
            </Box>
          ) : (
            <UploadElement content={content} mode={mode} />
          )
        ) : <UploadElement content={content} mode={mode} />}
      </Stack>
    </Box>
  )
}

export default Uploads;