import * as React from 'react';
import ModalEditorHeader from '../Layouts/ModalEditorHeader';
import { useNotes } from '../../contexts/notesContext';
import { addConnectionToFirestore, checkExistingConnection, removeConnectionFromFirestore } from '../../database/connections-db';
import { getTagsForNoteFromFirestore } from '../../database/notes-db';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../authContext';

export default function NotesPaneHeaderEdit({sidePaneOpen, setSidePaneOpen, allowEditing, noteToDisplay}) {
  
  const { accountId, selectedProjectId } = useParams()
  const { currentUser } = useAuth();

  const { handleSave, updateTitle, noteTitle, setNoteEditorOpen } = useNotes();
  const [ selectedTags, setSelectedTags ] = React.useState(noteToDisplay.tags ? noteToDisplay.tags : []);
  const [ pendingTag, setPendingTag ] = React.useState([]);
  const [ loading, setLoading ] = React.useState(false);
  const [ existingReferences, setExistingReferences ] = React.useState(noteToDisplay.references ? noteToDisplay.references : []);

  const handleUpdateTags = async (newTags = pendingTag) => {
    console.log("handleUpdateTags called with newTags", newTags);
   
    let currentTags = [];
    
    // Get current tags from server to double check
    currentTags = await getTagsForNoteFromFirestore({ accountId, noteId: noteToDisplay.id });
    
    // Format the new tags
    const formattedNewTags = newTags.map(tag => ({
        id: tag.id,
        boardId: tag.boardId,
        groupId: tag.groupId,
        accountId: tag.accountId || accountId,
        projectId: tag.projectId || selectedProjectId,
        tagId: tag.tagId || tag.id,
        title: tag.title,
        colour: tag.colour,
        references: tag.references || []
    }));

    try {
        // Find tags to add (tags in newTags but not in currentTags)
        const tagsToAdd = formattedNewTags.filter(newTag => 
            !currentTags.some(currentTag => currentTag.id === newTag.id)
        );

        console.log("tagsToAdd", tagsToAdd)

        // Find tags to remove (tags in currentTags but not in newTags)
        const tagsToRemove = currentTags.filter(currentTag => 
            !formattedNewTags.some(newTag => newTag.id === currentTag.id)
        );

        console.log("tagsToRemove", tagsToRemove)

        // Add new connections
        for (const tag of tagsToAdd) {
            const connectionExists = await checkExistingConnection({ 
                accountId, 
                fromId: noteToDisplay.id, 
                toId: tag.id 
            });
            
            if (!connectionExists) {
                await addConnectionToFirestore({
                    accountId,
                    project: selectedProjectId,
                    user: currentUser,
                    connection: {
                        fromType: "note",
                        fromId: noteToDisplay.id,
                        fromData: noteToDisplay,
                        fromTitle: noteToDisplay.noteTitle,
                        toType: "tag",
                        toId: tag.id,
                        toData: tag,
                        toTitle: tag.title,
                        comment: `Tag "${tag.title}" added to note "${noteToDisplay.noteTitle}"`,
                    }
                });
            }
        }

        // Remove old connections
        for (const tag of tagsToRemove) {
            // You'll need to implement this function
            await removeConnectionFromFirestore({
                accountId,
                fromId: noteToDisplay.id,
                toId: tag.id
            });
        }

    } catch (error) {
        console.error("Error updating tags", error);
    }

    setSelectedTags(formattedNewTags);
  }

  const handleSaveNote = async ({dontSave = false} = {}) => {
    if (dontSave) {
      setNoteEditorOpen(false);
      setSidePaneOpen(false);
      return;
    }
    setLoading(true);
    if (noteToDisplay.type === 'note' || noteToDisplay.type === 'upload' || noteToDisplay.type === 'link') {
      await handleSave({
        note: noteToDisplay,
        setNewCurrentNote: true
      })
      setLoading(false);
      setNoteEditorOpen(false);
      setSidePaneOpen(false);
    }
    if (noteToDisplay.type === 'persona') {
      console.log('save persona')
    }
  }

  React.useEffect(() => {
    setSelectedTags(noteToDisplay.tags ? noteToDisplay.tags : []);
    setExistingReferences(noteToDisplay.references ? noteToDisplay.references : []);
  },[noteToDisplay])

  return (
    <ModalEditorHeader 
      setSidePaneOpen={setSidePaneOpen}
      sidePaneOpen={sidePaneOpen}
      handleSave={handleSaveNote}
      fields={noteToDisplay}
      document={noteToDisplay}
      name={noteTitle}
      pendingTag={pendingTag}
      setPendingTag={setPendingTag}
      selectedTags={selectedTags}
      handleUpdateTags={handleUpdateTags}
      existingReferences={existingReferences}
      allowEditing={allowEditing}
      handleChangeTitle={updateTitle}
      type="note"
      loading={loading}
      setLoading={setLoading}
    />
  );
}
