import * as React from 'react';
import Stack from '@mui/joy/Stack';
import NoteMoreOptions from './NoteMoreOptions';
import Box from '@mui/joy/Box';
import Pins from '../Pins';
import TagPicker from '../Tags/Editor/TagPicker';
import OpenButton from '../Editor/plugins/OpenButton';
import MoveNote from '../MoveNote';
import { useNotes } from '../../contexts/notesContext';
import MakePrivate from '../MakePrivate';
import CreatedBy from '../CreatedBy';
import { useAuth } from './../../../authContext';
import { useParams } from 'react-router-dom';
import GenAi from '../GenAi';
import { addConnectionToFirestore, checkExistingConnection, removeConnectionFromFirestore } from '../../database/connections-db';
import { getTagsForNoteFromFirestore } from '../../database/notes-db';
import { SnackbarProvider } from '../../contexts/snackbarContext';
import FeedbackSnackbar from '../Snackbar';

export default function NotesPaneHeaderView({content}) {

  const { handleSave, noteEditorOpen, checkNoteEditPermissionsWithoutEditor } = useNotes();
  const { currentUser } = useAuth();
  const [ selectedTags, setSelectedTags ] = React.useState(content.tags ? content.tags : []);
  const [ pendingTag, setPendingTag ] = React.useState([]);
  const [ createdByUser, setCreatedByUser ] = React.useState(null);
  const [ existingReferences, setExistingReferences ] = React.useState(content.references ? content.references : []);
  
  const { accountUsers } = useAuth();
  const { accountId, selectedProjectId } = useParams()

  // Snackbar user feedback states
  const [showSnackbar, setShowSnackbar] = React.useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('neutral')
  const [snackbarMsg, setSnackbarMsg] = React.useState('')

  const handleUpdateTags = async (newTags = pendingTag) => {
    console.log("handleUpdateTags called with newTags", newTags);
   
    let currentTags = [];
    
    // Get current tags from server to double check
    currentTags = await getTagsForNoteFromFirestore({ accountId, noteId: content.id });
    
    // Format the new tags
    const formattedNewTags = newTags.map(tag => ({
        id: tag.id,
        boardId: tag.boardId,
        groupId: tag.groupId,
        accountId: tag.accountId || accountId,
        projectId: tag.projectId || selectedProjectId,
        tagId: tag.tagId || tag.id,
        title: tag.title,
        colour: tag.colour,
        references: tag.references || []
    }));

    try {
        let hasInvalidTags = false;  // Add flag to track invalid tags

        // Find tags to add (tags in newTags but not in currentTags)
        const tagsToAdd = formattedNewTags.filter(newTag => 
            !currentTags.some(currentTag => currentTag.id === newTag.id)
        );

        console.log("tagsToAdd", tagsToAdd)

        // Find tags to remove (tags in currentTags but not in newTags)
        const tagsToRemove = currentTags.filter(currentTag => 
            !formattedNewTags.some(newTag => newTag.id === currentTag.id)
        );

        console.log("tagsToRemove", tagsToRemove)

        // Add new connections
        for (const tag of tagsToAdd) {
            const connectionExists = await checkExistingConnection({ 
                accountId, 
                fromId: content.id, 
                toId: tag.id 
            });
            
            if (!connectionExists) {
              if (!tag.boardId || !tag.groupId) {
                console.error("Tag boardId or groupId is missing");
                setShowSnackbar(true);
                setSnackbarSeverity('danger');
                setSnackbarMsg('Error updating tags');
                hasInvalidTags = true;  // Set flag when invalid tag found
                continue;
              } else {
                await addConnectionToFirestore({
                    accountId,
                    project: selectedProjectId,
                    user: currentUser,
                    connection: {
                        fromType: "note",
                        fromId: content.id,
                        fromData: content,
                        fromTitle: content.noteTitle,
                        toType: "tag",
                        toId: tag.id,
                        toData: tag,
                        toTitle: tag.title,
                        comment: `Tag "${tag.title}" added to note "${content.noteTitle}"`,
                    }
                });
              }
            } else {
                // If the connection already exists, but it isnt in the currentTags, then we need to likely delete the existing connection and re-add it
                console.log("connection already exists, but it isnt in the currentTags, so we need to delete the existing connection and re-add it")
                await removeConnectionFromFirestore({
                    accountId,
                    fromId: content.id,
                    toId: tag.id
                });
                await addConnectionToFirestore({
                    accountId,
                    project: selectedProjectId,
                    user: currentUser,
                });
            }
        }

        // Remove old connections
        for (const tag of tagsToRemove) {
          console.log("attempting to remove tag to remove", tag)
            // You'll need to implement this function
            await removeConnectionFromFirestore({
                accountId,
                fromId: content.id,
                toId: tag.id
            });
        }

        // Only update selectedTags if all tags were valid
        if (!hasInvalidTags) {
            setSelectedTags(formattedNewTags);
        }
    } catch (error) {
        console.error("Error updating tags", error);
        setShowSnackbar(true);
        setSnackbarSeverity('danger');
        setSnackbarMsg('Error updating tags');
    }
  }

  React.useEffect(() => {
    setSelectedTags(content.tags ? content.tags : []);
    setExistingReferences(content.references ? content.references : []);
  },[content])

  React.useEffect(() => {
    if (accountUsers && accountUsers.length > 0 && content.createdBy) {
      const user = accountUsers.find(user => user.id === content.createdBy);
      if (user) {
        setCreatedByUser(user);
      } else {
        setCreatedByUser({ displayName: "Deleted User (" + content.createdBy + ")" });
      }
    }
  },[accountUsers, content.createdBy])
  
  return (
    <>
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        p: .25
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', ml: .25 }}>
        <Pins noteToDisplay={content} />
        {/* <DockItem type={content.type} /> */}
        {/* <Tags /> */}
        { checkNoteEditPermissionsWithoutEditor({ content, currentUser }) &&
        <TagPicker
          handleUpdateTags={handleUpdateTags} 
          setPendingTag={setPendingTag}
          pendingTag={pendingTag}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          noteToDisplay={content}
          showSnackbar={showSnackbar}
          setShowSnackbar={setShowSnackbar}
          snackbarSeverity={snackbarSeverity}
          setSnackbarSeverity={setSnackbarSeverity}
          snackbarMsg={snackbarMsg}
          setSnackbarMsg={setSnackbarMsg}
          existingReferences={existingReferences}
        />
        }
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ mt: .5 }}><NoteMoreOptions content={content} /></Box>
        { createdByUser && <CreatedBy user={createdByUser} /> }
        { !noteEditorOpen && content.type !== "link" && <OpenButton noteToDisplay={content} /> }        
      </Box>
      <FeedbackSnackbar
        openSetting={showSnackbar}
        setShowSnackbar={setShowSnackbar}
        variantSetting={'solid'}
        colorSetting={snackbarSeverity}
        message={snackbarMsg}
      />
    </Stack>
    </>
  );
}