import React, { useState, useEffect, createContext, useContext } from 'react';
import { retrievePersonasFromFirestore, deletePersona, saveToFirestore } from '../database/personas-db';
import { useParams } from 'react-router-dom';
import { useContextualPane } from './contextualPane';
import { emptyPersonaFields } from '../data/personas/emptyPersonaFields';
import { convertToPersonaFields } from '../data/personas/convertToPersonaFields';

// Create the context
const PersonasContext = createContext();

// Create a provider component
export function PersonasProvider({ children }) {

  const { accountId, selectedProjectId } = useParams()
  const { activeItem, setActiveItem } = useContextualPane()
  const [persona, setPersona] = useState()
  const [personas, setPersonas] = useState([])
  const [open, setOpen] = useState(false);
  const [ name, setName ] = useState('')
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [pendingTag, setPendingTag] = React.useState([]);
  const [editChecked, setEditChecked] = React.useState(true);

  // Snackbar user feedback states
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('neutral')
  const [snackbarMsg, setSnackbarMsg] = useState('')

  const handleCreatePersona = async () => {
    // Create a new persona in db
    const newPersona = emptyPersonaFields
    try {
      setPersona(newPersona)
      setOpen(true)
      setEditChecked(true)
    } catch (error) {
      console.error("Error creating persona", error)
    }
  }

  const handleCreatePersonaFromAi = async (persona) => {
    console.log("handleCreatePersonaFromAi", persona)
    const newPersona = convertToPersonaFields(persona)
    console.log("newPersona", newPersona)
    try {
      const response = await saveToFirestore({ 
        id: null, 
        persona: newPersona.data, 
        name: persona.name, 
        pendingTag: [],
        accountId: accountId, 
        selectedProjectId: selectedProjectId 
      })
      console.log("response from saving persona", response)
      const newPersonas = await retrievePersonasFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId})
      if (newPersonas) {
        setPersonas(newPersonas)
      }
    } catch (error) {
      console.error("Error creating persona from AI", error)
    }
  }

  const handleShowSnackbar = ({ reason, message }) => {
    if (reason === 'success') {
      setSnackbarSeverity('success')
      setSnackbarMsg(message)
      setShowSnackbar(true)
    }
  }

  const handleUpdateCurrentPersona = async ( { fields, id = null, name, pendingTag } ) => {
    // handles remote save
    const response = await saveToFirestore({ 
        id, 
        persona: fields, 
        name, 
        pendingTag,
        accountId: accountId, 
        selectedProjectId: selectedProjectId 
      })
    setOpen(false)
    handleShowSnackbar({reason: 'success', message: 'Persona updated'})
    const newPersonas = await retrievePersonasFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId})
    if (newPersonas) {
      setPersonas(newPersonas)
    }
  }

  const handlePersonaDeletion = async (id) => {
    // handles remote deletion
    const response = await deletePersona({ personaId: id, accountId: accountId })
    ////console.log("response from saving persona", response)
    setOpen(false)
    handleShowSnackbar({reason: 'success', message: 'Persona deleted'})
    const newPersonas = await retrievePersonasFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId})
    if (newPersonas) {
      setPersonas(newPersonas)
    }
  }

  const handleSetPersona = (value,name) => {
    console.log("setting persona", value,name)
    setName(name)
    setPersona(value)
    setSelectedTags(value.tags)
    setPendingTag(value.tags)
    // setOpen(true)
  }

  const handleUpdatePersonaProject = async ({ newProjectId, content }) => {
    console.log("handleUpdatePersonaProject", newProjectId, content)
    await saveToFirestore({ 
      id: content.id,
      persona: content.data,
      name: content.name,
      accountId: accountId,
      selectedProjectId: newProjectId
    })
    setOpen(false)
    handleShowSnackbar({reason: 'success', message: 'Persona moved'})
    await fetchPersonas();
  }

  const handlePinPersona = async ({ persona, trueOrFalse }) => {
    try {
      // Validate inputs
      if (!persona?.id || !accountId || !selectedProjectId) {
        console.error("Missing required fields:", { 
          hasPersonaId: !!persona?.id, 
          hasAccountId: !!accountId, 
          hasProjectId: !!selectedProjectId 
        });
        handleShowSnackbar({reason: 'error', message: 'Missing required data'});
        return;
      }

      // Create a minimal update object
      const personaData = {
        id: persona.id,
        persona: persona.data,
        name: persona.name || '',
        accountId,
        selectedProjectId,
        pinPersona: Boolean(!persona.pinned)
      };

      // Then save the updated version
      try {
        await saveToFirestore(personaData);
        // Update local state with the modified persona
        const updatedPersona = {
          ...persona,
          pinned: !persona.pinned
        };
        setPersona(updatedPersona);
      } catch (error) {
        console.error("Error updating persona:", error);
        handleShowSnackbar({
          reason: 'error', 
          message: 'We couldn\'t pin the persona just now'
        });
      }
      
      // Refresh the personas list
      await fetchPersonas();

      handleShowSnackbar({
        reason: 'success', 
        message: trueOrFalse ? 'Persona pinned' : 'Persona unpinned'
      });

    } catch (error) {
      console.error("Error updating persona pin status:", error);
      handleShowSnackbar({
        reason: 'error', 
        message: 'Failed to update persona pin status'
      });
    }
  }

  const fetchPersonas = async () => {
    const results = await retrievePersonasFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId})
    console.log("personas fetched", results)
    if (results.length > 0) { setPersonas(results) } 
  }

  useEffect(() => {
    //console.log("Selected project changed, refetch personas")
    const fetchData = async () => {
      try {
        const data = await retrievePersonasFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId});
        return data;
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
        throw error; // Rethrow the error so that the outer catch block can handle it
      }
    };
    if (selectedProjectId) {
      const getData = async () => {
        try {
          const data = await fetchData();
          // if item was deleted, then select something again
          // if item was updated, then maintain selection
          // selectedNote will be emptied on deletion of record, so use this to tell
          if (data.length > 0) {
            setPersonas(data);
          } else {
            setPersonas([]);
          }
        } catch (error) {
          // Handle the error if needed
          //console.log("Error fetching personas", error)
        }
      };
      getData();
    }
  },[selectedProjectId])

  const value = {
    persona,
    setPersona,
    personas,
    setPersonas,
    personaEditorOpen: open,
    setPersonaEditorOpen: setOpen,
    showSnackbar,
    setShowSnackbar,
    snackbarSeverity,
    handleCreatePersonaFromAi,
    setSnackbarSeverity,
    snackbarMsg,
    setSnackbarMsg,
    handleCreatePersona,
    handleSetPersona,
    handleUpdateCurrentPersona,
    handlePersonaDeletion,
    name, setName,
    pendingTag,
    setSelectedTags,
    selectedTags,
    setPendingTag,
    editChecked,
    fetchPersonas,
    setEditChecked,
    handleUpdatePersonaProject,
    handlePinPersona
  };

  return <PersonasContext.Provider value={value}>{children}</PersonasContext.Provider>;
}

// Create a custom hook that components can use to access the context
export function usePersonas() {
    return useContext(PersonasContext);
}