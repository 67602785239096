import React, { useState, useEffect, useRef } from 'react';
import { Box, ButtonGroup, Button, Card, Typography, Tooltip, IconButton } from '@mui/joy';
import Add from '@mui/icons-material/Add';
import TextFieldsIcon from '@mui/icons-material/TextFields'; // Any text field
import LabelIcon from '@mui/icons-material/Label'; // tag
import RouteIcon from '@mui/icons-material/Route'; // user journey
import WebIcon from '@mui/icons-material/Web'; // content
import LinkIcon from '@mui/icons-material/Link'; // link
import SignpostIcon from '@mui/icons-material/Signpost'; // user flow
import ArticleIcon from '@mui/icons-material/Article'; // templates // pages
import AccountTreeIcon from '@mui/icons-material/AccountTree'; // sitemap
import AddReactionIcon from '@mui/icons-material/AddReaction'; // feature
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded'; // notes
import HighlightIcon from '@mui/icons-material/Highlight'; // highlight
import PersonAddIcon from '@mui/icons-material/PersonAdd'; // persona
import BoltIcon from '@mui/icons-material/Bolt'; // incite
import NorthWestIcon from '@mui/icons-material/NorthWest'; // Open Create Docs (collapsed)
import SouthIcon from '@mui/icons-material/South'; // Close Create Docs (expanded)
import RefreshIcon from '@mui/icons-material/Refresh'; // Refresh
import { useAiResults } from '../../contexts/aiResultsContext';
import { useAiPane } from '../../contexts/aiPaneContext';
import SummariseResult from '../GenAi/AiPane/ResultComponents/SummariseResult';
import FeaturesResults from '../GenAi/AiPane/ResultComponents/FeaturesResults';
import PersonasResult from '../GenAi/AiPane/ResultComponents/PersonasResult';
import ConnectionResultsForInlinePane from '../Connections/ConnectionResultsForInlinePane';
import InlineLoader from '../Loaders/InlineLoader';
import { useAuth } from '../../../authContext';
import SidePaneSearch from '../Search/SidePaneSearch';

function Sidepane({ type, noteToDisplay }) {

  const { aiResults, setAiResults, loading, setLoading, selectedAiView, setSelectedAiView } = useAiResults();
  const { summariseThisPdf, summariseThisText, editorContent, featureSuggestThis, generateFeaturesFromPdf, generatePersonas } = useAiPane();
  const { currentUser } = useAuth();

  const processedViews = useRef(new Set());

  const handleSelectAiView = (view) => {
    setSelectedAiView(view);
  }

  const handleRefetchView = async () => {
    setLoading(true);
    if (selectedAiView === 'summary') {
      setAiResults((prev) => ({ ...prev, summary: null }));
      if (type === 'note') {
        await summariseThisText(editorContent, noteToDisplay.id);
      }
      else if (type === 'upload') {
        console.log("refetching upload with noteToDisplay", noteToDisplay)
        if (noteToDisplay.originalTitle) {
          await summariseThisPdf(currentUser._delegate.uid, noteToDisplay.originalTitle, noteToDisplay.id);
        }
      }
    } else if (selectedAiView === 'connections') {
      console.log("refetching connections")
    } else if (selectedAiView === 'features') {
      setAiResults((prev) => ({ ...prev, features: null }));
      if (type === 'note') {
        await featureSuggestThis(editorContent, noteToDisplay.id);
      }
      else if (type === 'upload') {
        if (noteToDisplay.originalTitle) {
          await generateFeaturesFromPdf(currentUser._delegate.uid, noteToDisplay.originalTitle, noteToDisplay.id);
        }
      }
    } else if (selectedAiView === 'journeys') {
      setAiResults((prev) => ({ ...prev, journeys: null }));
    } else if (selectedAiView === 'sitemap') {
      setAiResults((prev) => ({ ...prev, sitemap: null }));
    } else if (selectedAiView === 'persona') {
      setAiResults((prev) => ({ ...prev, persona: null }));
    }
  }

  const handleSummaryChange = async () => {
    // Check if there is a summary already that matches the current document
    if (!aiResults?.summary || aiResults?.summary?.id !== noteToDisplay.id) {
      if (noteToDisplay && noteToDisplay.type === "note") {
        // If not already a summary result, grab a new one
        if (editorContent) {
          console.log("Removing auto summary for now in case it is causing unneccessarily high amount of requests")
          // await summariseThisText(editorContent, noteToDisplay.id);
        }
      } else if (noteToDisplay && noteToDisplay.type === "upload") {
        // use originalTitle to determine if its a pdf or not
        if (noteToDisplay.originalTitle) {
          console.log("Removing auto summary for now in case it is causing unneccessarily high amount of requests")
          // await summariseThisPdf(currentUser._delegate.uid, noteToDisplay.originalTitle, noteToDisplay.id);
        }
      }
    } else {
      // If there is a summary already that matches the current document, do nothing
      console.log("There is already a summary that matches the current document, do nothing", aiResults?.summary?.id, noteToDisplay.id)
    }
  }

  const handleFeaturesChange = async () => {
    console.log("handleFeaturesChange", aiResults)
    if (!aiResults?.features || aiResults?.features?.id !== noteToDisplay.id) {
      if (noteToDisplay && noteToDisplay.type === "note") {
        if (editorContent) {
          await featureSuggestThis(editorContent, noteToDisplay.id);
        }
      } else if (noteToDisplay && noteToDisplay.type === "upload") {
        if (noteToDisplay.originalTitle) {
          await generateFeaturesFromPdf(currentUser._delegate.uid, noteToDisplay.originalTitle, noteToDisplay.id);
        }
      }
    } else {
      // If there is a features already that matches the current document, do nothing
      console.log("There is already a features that matches the current document, do nothing")
    }
  }

  const handlePersonasChange = async () => {
    console.log("handlePersonasChange", aiResults);
    if (!noteToDisplay) return;
    
    if (!aiResults?.persona || aiResults?.persona?.id !== noteToDisplay.id) {
      if (noteToDisplay.type === "upload" && noteToDisplay.originalTitle) {
        await generatePersonas(currentUser._delegate.uid, noteToDisplay.originalTitle, noteToDisplay.id);
      }
    } else {
      console.log("There is already a personas that matches the current document, do nothing");
    }
  }

  const handleChangeAiView = async () => {
    const viewKey = `${selectedAiView}-${noteToDisplay?.id}`;
    if (processedViews.current.has(viewKey)) {
      return;
    }

    if (selectedAiView === 'summary') {
      await handleSummaryChange();
    } else if (selectedAiView === 'features') {
      await handleFeaturesChange();
    } else if (selectedAiView === 'persona') {
      await handlePersonasChange();
    }

    processedViews.current.add(viewKey);
  }

  useEffect(() => {
    processedViews.current.clear();
  }, [noteToDisplay]);

  useEffect(() => {
    console.log("selectedAiView changed", selectedAiView);
    handleChangeAiView();
  }, [selectedAiView]);

  useEffect(() => {
    console.log("aiResults changed", aiResults)
  }, [aiResults])

  return (
    <Box 
      sx={{
        pt: 2,
        pb: 2,
        pl: 1,
        pr: 1,
        borderRadius: '10px',
        backgroundColor: 'background.surface',
        height: '100vh',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      { selectedAiView !== 'connections' && selectedAiView !== 'search' &&
        <ButtonGroup spacing={1} size="sm" orientation="vertical" sx={{ position: 'absolute', top: 10, right: 10 }}>
        {/* SIDE PANE */}
        <Tooltip placement="left" variant="plain" color="neutral" title="AI Summary" onClick={() => handleSelectAiView('summary')}>
          <IconButton variant={ selectedAiView === 'summary' ? 'solid' : '' } size="sm"><AssignmentRoundedIcon /></IconButton>
        </Tooltip>
        {/* <Tooltip variant="plain" color="neutral" title="AI Suggested Highlights">
          <IconButton size="sm" variant='plain'><HighlightsIcon /></IconButton>
        </Tooltip> */}
        <Tooltip placement="left" variant="plain" color="neutral" title="AI Suggested Features">
          <IconButton onClick={() => handleSelectAiView('features')} variant={ selectedAiView === 'features' ? 'solid' : '' } size="sm"><AddReactionIcon /></IconButton>
        </Tooltip>
        <Tooltip variant="plain" color="neutral" title="AI Suggested Personas">
          <IconButton onClick={() => handleSelectAiView('persona')} variant={ selectedAiView === 'persona' ? 'solid' : '' } size="sm"><PersonAddIcon /></IconButton>
        </Tooltip>
        <Tooltip placement="left" variant="plain" color="neutral" title="AI Suggested Journeys">
          <IconButton onClick={() => handleSelectAiView('journeys')} variant={ selectedAiView === 'journeys' ? 'solid' : '' } size="sm"><RouteIcon /></IconButton>
        </Tooltip>
        <Tooltip placement="left" variant="plain" color="neutral" title="AI Suggested Sitemap">
          <IconButton onClick={() => handleSelectAiView('sitemap')} variant={ selectedAiView === 'sitemap' ? 'solid' : '' } size="sm"><AccountTreeIcon /></IconButton>
        </Tooltip>
        </ButtonGroup>
      }
      <Box sx={{ 
        mr: selectedAiView === 'connections' || selectedAiView === 'search' ? 0 : 5,
        pb: 2,
        height: 'calc(100% - 40px)',
        overflowY: 'auto'
      }} className="ai-results-pane-container">
        <Typography 
          endDecorator={ selectedAiView !== 'connections' && selectedAiView !== 'search' &&
            <Tooltip variant='plain' color='neutral' title="Refetch results">
              <IconButton variant='plain' size='sm' onClick={() => handleRefetchView()}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          } 
          sx={{ mb: 2 }} 
          level="h5"
        >
          { selectedAiView === 'summary' ? 'AI Summary' : 
          selectedAiView === 'features' ? 'AI Suggested Features' : 
          selectedAiView === 'journeys' ? 'AI Suggested Journeys' : 
          selectedAiView === 'sitemap' ? 'AI Suggested Sitemap' : 
          selectedAiView === 'persona' ? 'AI Suggested Personas' : 
          selectedAiView === 'connections' ? 'Connections' :
          selectedAiView === 'search' ? '' :
          '' }
        </Typography>
        { loading ? <InlineLoader color={"#6B7280"} width={24} height={24} loading={loading} /> :
          selectedAiView === 'search' ?
            <>
              <Box>
                <SidePaneSearch />
              </Box>
            </>
          : selectedAiView === 'connections' ?
            <>
              <Box>
                <ConnectionResultsForInlinePane />
              </Box>
            </>
          : selectedAiView === 'summary' ?
            aiResults?.summary &&
            <>
              <Box>
                <SummariseResult result={aiResults} />
              </Box>
            </>
          : selectedAiView === 'features' ?
            aiResults?.features &&
            <>
              <Box>
                <FeaturesResults result={aiResults} />
              </Box>
            </>
          : selectedAiView === 'persona' &&
            aiResults?.persona &&
            <>
              <Box>
                <PersonasResult result={aiResults} />
              </Box>
            </>
        }
      </Box>
    </Box>
  )
}

export default Sidepane;