import database from '../../configs/firebaseConfig'; 
const db = database.firestore(); // Access Firestore database

// Add new connection to Firestore
export async function addConnectionToFirestore({ accountId, project, connection, user }) {
  console.log("addConnectionToFirestore called with connection object", connection)
  try {
    const docRef = await db.collection(`accounts/${accountId}/connections`).add({
      ...connection,
      project: project,
      created: new Date(),
      updated: new Date(),
      user: user._delegate.uid
    });
    const doc = await docRef.get();
    const data = doc.data()
    return { ...data, id: doc.id }
  } catch(error) {
    console.error("Error adding connection to Firestore:", error);
  }
}

// Retrieve all connections for project
export async function fetchConnectionsFromFirestore({ accountId, project }) {
  try {
    const connectionsRef = await db.collection(`accounts/${accountId}/connections`).where("project", "==", project).get();
    const connections = connectionsRef.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    return { connections }
  }
  catch (error) {
    console.error("Error fetching connections from Firestore:", error);
  }
}

// Retrieve all connections for fromId
export async function fetchConnectionsFromFirestoreByFromId({ accountId, project, fromId }) {
  try {
    const connectionsRef = await db.collection(`accounts/${accountId}/connections`).where("project", "==", project).where("fromId", "==", fromId).get();
    const connections = connectionsRef.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    return { connections }
  }
  catch (error) {
    console.error("Error fetching connections from Firestore:", error);
  }
}

// First, create a helper function to check for existing connections
export async function checkExistingConnection({ accountId, fromId, toId }) {
  // Add validation
  if (!accountId || !fromId || !toId) {
    console.error('Missing required parameters:', { accountId, fromId, toId });
    return false;
  }

  try {
    const existingConnection = await db.collection(`accounts/${accountId}/connections`)
      .where('fromId', '==', fromId)
      .where('toId', '==', toId)
      .limit(1)
      .get();
      
    return !existingConnection.empty;
  } catch (error) {
    console.error('Error checking existing connection:', error);
    return false;
  }
};

// Remove connection from Firestore
export async function removeConnectionFromFirestore({ accountId, fromId, toId }) {
  // Add validation
  if (!accountId || !fromId || !toId) {
    console.error('Missing required parameters:', { accountId, fromId, toId });
    return false;
  }

  try {
    // First, find the connection document that matches both fromId and toId
    const connectionQuery = await db.collection(`accounts/${accountId}/connections`)
      .where('fromId', '==', fromId)
      .where('toId', '==', toId)
      .limit(1)
      .get();

    console.log("connectionQuery to remove", connectionQuery)

    // If no matching connection is found, return false
    if (connectionQuery.empty) {
      console.log('No matching connection found to remove');
      return false;
    }

    // Delete the found connection document
    await connectionQuery.docs[0].ref.delete();
    return true;

  } catch (error) {
    console.error("Error removing connection from Firestore:", error);
    return false;
  }
}