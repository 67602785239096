import React from 'react';
import { Box, IconButton, Tooltip, Input, Typography } from '@mui/joy';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TagPicker from '../../Tags/Editor/TagPicker';
import EditorMenu from '../../EditerMenu';
import AiMagicButton from '../../Buttons/AiMagic';
import NoteMoreOptions from '../../MessagesPane/NoteMoreOptions';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CableIcon from '@mui/icons-material/Cable';
import { useNotes } from '../../../contexts/notesContext';
import { focusEditorAtStart } from '../../Editor/plugins/EditorUtils';
import InlineLoader from '../../Loaders/InlineLoader';
import { useAiPane } from '../../../contexts/aiPaneContext';
import { useAiResults } from '../../../contexts/aiResultsContext';
import CreateButton from '../../Buttons/CreateButton';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';

function ModalEditorHeader({ 
  loading = false, 
  setLoading = () => {}, 
  allowEditing, 
  setSidePaneOpen, 
  sidePaneOpen, 
  handleSave, 
  fields, 
  document, 
  name, 
  pendingTag, 
  setPendingTag, 
  editChecked, 
  handleChangeTitle, 
  handleUpdateTags, 
  selectedTags, 
  type,
  existingReferences,
  setExistingReferences,
}) {

  const { 
    summariseThisText, 
    summariseThisPdf, 
    editorContent, 
    featureSuggestThis, 
    suggestTodos, 
    generateFeatures, 
    generatePersonas 
  } = useAiPane()
  const { aiResults, setAiResults, selectedAiView, setSelectedAiView } = useAiResults();
  const [personaData, setPersonaData] = React.useState()
  const [ editingTitle, setEditingTitle ] = React.useState(false)
  const titleRef = React.useRef(null)

  // Snackbar user feedback states
  const [showSnackbar, setShowSnackbar] = React.useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('neutral')
  const [snackbarMsg, setSnackbarMsg] = React.useState('')

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === 'ArrowDown') {
      e.preventDefault();
      handleUpdateTitle();
    }
  };

  React.useEffect(() => {
    if (type === 'persona') {
      console.log('persona name grabber document', document)
      let personaData = document.data
  
      if (typeof personaData === 'object' && personaData !== null) {
        // Convert the persona object into an array
        const personaArray = Object.values(personaData);
    
        // Initialize variables for the three values
        let nameValue = '';
        let imageValue = '';
        let roleValue = '';
    
        // Iterate over each object in the persona array
        personaArray.forEach(obj => {
          if (obj.field_title === 'Name') {
            nameValue = obj.field_value;
          }
          if (obj.type === 'image') {
            imageValue = obj.field_value;
          }
          if (obj.field_title === 'Role') {
            roleValue = obj.field_value;
          }
        });
    
        // Log the extracted values for debugging
        //console.log("Extracted Values:", { nameValue, imageValue, roleValue });
    
        // Set the three values to the state
        setPersonaData({ name: nameValue, image: imageValue, role: roleValue, tags: document.tags });
      }
    }
  }, [document]);

  const handleUpdateTitle = () => {
    setEditingTitle(false)
    const value = titleRef.current?.querySelector('input')?.value
    console.log("handleUpdateTitle with value", value)
    handleChangeTitle({ target: { value: value || 'Untitled' } })
  }

  const handleOpenAiSuggestions = async () => {
    if (!sidePaneOpen) {
      setSidePaneOpen(true);
    } else if (sidePaneOpen && selectedAiView === 'summary') {
      setSidePaneOpen(false);
    }
    // Handler in Sidepane that fetches based on the selectedAiView
    setSelectedAiView('summary');
  }

  const handleOpenConnections = () => {
    if (!sidePaneOpen) {
      setSidePaneOpen(true);
    } else if (sidePaneOpen && selectedAiView === 'connections') {
      setSidePaneOpen(false);
    }
    setSelectedAiView('connections');
  }

  const handleOpenSearch = () => {
    console.log("handleOpenSearch")
    if (!sidePaneOpen) {
      setSidePaneOpen(true);
    } else if (sidePaneOpen && selectedAiView === 'search') {
      setSidePaneOpen(false);
    }
    setSelectedAiView('search');
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      { loading ?
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, mt: 1, mr: 2 }}>
          <InlineLoader color={"#6B7280"} width={24} height={24} loading={loading} />
        </Box>
      :
        <Tooltip variant="outlined" color="neutral" title={ allowEditing ? "Save & Exit" : "Exit" } arrow>
          <IconButton onClick={() => allowEditing ? handleSave() : handleSave({dontSave: true})} size="md" sx={{ mt: 1, display: 'flex' }}>
          <ChevronLeftIcon />
        </IconButton>
        </Tooltip>
      }
      <Box sx={{ display: 'flex' }}>
        { editingTitle 
          ?
            type !== 'persona' 
              ? <Input 
                  defaultValue={document && document.noteTitle && document.noteTitle !== '' ? document.noteTitle : 'Untitled'} 
                  onBlur={(e) => handleUpdateTitle()} 
                  onKeyDown={handleKeyDown}
                  size="lg" 
                  autoFocus
                  ref={titleRef}
                  placeholder="Enter title"
                  sx={{ minWidth: { sm: 300, lg: 600 }, mt: 3, mb: 2 }} 
                  endDecorator={
                    editingTitle ? 
                    <>
                      <IconButton sx={{ pr: 1 }} aria-label="Rename note" size="sm" onClick={handleUpdateTitle}>
                        <DoneIcon fontSize='md' />
                      </IconButton>
                    </>
                    :
                    <IconButton aria-label="Edit" size="sm" onClick={() => setEditingTitle(true)}>
                      <EditIcon fontSize='md' />
                    </IconButton>
                  }
                />
              : <Typography level="h3" sx={{ mt: 3, mb: 2 }}>{ personaData && personaData.name && personaData.name }</Typography>
          :
            type !== 'persona'
              ? <Box 
                  onClick={() => allowEditing ? setEditingTitle(true) : null} 
                  sx={{ px: 1, ml: -1, mt: 3, mb: 2, borderRadius: '4px', display: 'flex', alignItems: 'center', border: '1px solid transparent', '&:hover': allowEditing ? { backgroundColor: '#ece6f2', border: '1px solid #ece6f2' } : {} }}
                >
                  <Typography level="h3" sx={{ '&:hover': { cursor: allowEditing ? 'pointer' : 'default' } }}>{ name !== '' ? name : 'Untitled' }</Typography>
                </Box>
              : <Typography level="h3" sx={{ mt: 3, mb: 2 }}>{ personaData && personaData.name && personaData.name }</Typography>
        }
        
        <Box sx={{ 
          mt: type === "persona" && personaData && personaData.name !== "" || type !== "persona" && document && document.name !== "" ? 2 : .5, 
          ml: 0 
        }}>
          { type !== 'persona' &&
          <TagPicker 
            handleUpdateTags={handleUpdateTags}
            pendingTag={pendingTag}
            setPendingTag={setPendingTag}
            selectedTags={selectedTags} 
            noteToDisplay={document}
            showSnackbar={showSnackbar}
            setShowSnackbar={setShowSnackbar}
            snackbarSeverity={snackbarSeverity}
            setSnackbarSeverity={setSnackbarSeverity}
            snackbarMsg={snackbarMsg}
            setSnackbarMsg={setSnackbarMsg}
            existingReferences={existingReferences}
          />
          }
        </Box>
      </Box>
      <Box sx={{ zIndex: 9999, display: 'flex', ml: 'auto' }}>
        <NoteMoreOptions content={document} />
        <Tooltip variant="plain" color="neutral" title="Connections">
          <IconButton onClick={() => handleOpenConnections()}>
            <CableIcon />
          </IconButton>
        </Tooltip>
        <Tooltip variant="plain" color="neutral" title="Search">
          <IconButton onClick={() => handleOpenSearch()}>
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <Tooltip variant="plain" color="neutral" title="Open AI Suggestions">
          <IconButton onClick={() => handleOpenAiSuggestions()}>
            <AiMagicButton />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  )
}

export default ModalEditorHeader;