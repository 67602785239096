import database, { storage } from '../../configs/firebaseConfig'; 
import { checkTeamMembership } from './utilities-db';
import { ref, getStorage, uploadBytes, listAll, getDownloadURL } from "firebase/storage"
import { v4 } from 'uuid';
import { 
  getFirestore, 
  collection, 
  query, 
  where, 
  getCountFromServer 
} from "firebase/firestore";

const db = database.firestore(); // Access Firestore database
const emptyEditor = '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}'

export async function getNote({ noteId, accountId }) {
  try {
    const docRef = await db.collection(`accounts/${accountId}/notes`).doc(noteId).get();
    const data = docRef.data();
    return { id: docRef.id, ...data };
  } catch (error) {
    console.error("Error retrieving note from Firestore:", error);
    return null;
  }
}

export async function addPropertyToAllDocuments({accountId}) {
  try {
    const snapshot = await db.collection(`accounts/${accountId}/notes`).get();

    const batch = db.batch();

    snapshot.docs.forEach((doc) => {
      const docRef = db.collection(`accounts/${accountId}/notes`).doc(doc.id);
      batch.update(docRef, { bin: false });
    });

    await batch.commit();

  } catch (error) {
    console.error("Error adding property to all documents", error)
  }  
}

export async function makeNotePrivate({ noteId, accountId, password }) {
  console.log("makeNotePrivate", noteId, accountId, password)
  const docRef = await db.collection(`accounts/${accountId}/notes`).doc(noteId).set({
    private: true,
    privatePassword: password
  }, { merge: true });
}

export async function makeNotePublic({ noteId, accountId }) {
  console.log("makeNotePublic", noteId, accountId)
  const docRef = await db.collection(`accounts/${accountId}/notes`).doc(noteId).set({
    private: false,
    privatePassword: ''
  }, { merge: true });
}

export async function retrieveNotesFromFirestore({accountId, selectedProjectId, limit = 20, lastDoc = null}) {
  console.log('retrieveNotesFromFirestore called with:', {
    accountId,
    selectedProjectId,
    limit,
    hasLastDoc: !!lastDoc
  });

  try {
    let query = await db.collection(`accounts/${accountId}/notes`)
      .where("project", "==", selectedProjectId ? selectedProjectId : '')
      .where("bin", "!=", true)
      .orderBy("updated", "desc")
      .limit(limit);

    if (lastDoc) {
      query = query.startAfter(lastDoc);
    }

    const snapshot = await query.get();
    console.log(`Retrieved ${snapshot.docs.length} notes`);

    const notes = [];
    let transcriptionQueries = 0;
    
    for (const doc of snapshot.docs) {
      const data = doc.data();
      const note = {
        id: doc.id,
        noteTitle: data.noteTitle,
        originalTitle: data.originalTitle ? data.originalTitle : '',
        type: data.type ? data.type : 'note',
        project: data.project,
        description: data.description ? data.description : '',
        timestamp: data.timestamp.toDate(),
        bin: data.bin,
        tags: data.tags,
        url: data.url ? data.url : '',
        pinned: data.pinned ? data.pinned : false,
        updated: data.updated ? data.updated.toDate() : null,
        createdBy: data.createdBy,
        private: data.private ? data.private : false,
        privatePassword: data.privatePassword ? data.privatePassword : '',
        transcriptStatus: data.transcriptStatus ? data.transcriptStatus : '',
        transcriptionId: data.transcriptionId ? data.transcriptionId : ''
      };

      if (note.type === "upload") {
        transcriptionQueries++;
        if (transcriptionQueries > 10) {
          console.warn(`High number of transcription queries: ${transcriptionQueries}`);
        }
        const transcriptionsSnapshot = await db.collection(`accounts/${accountId}/notes/${doc.id}/transcriptions`).get();
        note.transcriptions = transcriptionsSnapshot.docs.map(transcriptionDoc => transcriptionDoc.data());
      }

      notes.push(note);
    }

    return {
      notes,
      lastVisible: snapshot.docs[snapshot.docs.length - 1],
      hasMore: notes.length === limit
    };
  } catch (error) {
    console.error("Error retrieving notes from Firestore:", error);
    return { notes: [], lastVisible: null, hasMore: false };
  }
}

export async function getTagsForNoteFromFirestore({ accountId, noteId }) {
  try {
    const tagsSnapshot = await db.collection(`accounts/${accountId}/notes/${noteId}/tags`).get();
    const tags = tagsSnapshot.docs.map(tagDoc => tagDoc.data());
    return tags;
  } catch (error) {
    console.error("Error retrieving tags for note from Firestore:", error);
    return [];
  }
}

export async function getNoteReferencesFromFirestore({ noteId, accountId }) {
  try {
    const referencesSnapshot = await db.collection(`accounts/${accountId}/notes/${noteId}/references`).get();
    const references = referencesSnapshot.docs.map(referenceDoc => referenceDoc.data());
    return references;
  } catch (error) {
    console.error("Error retrieving references for note from Firestore:", error);
    return [];
  }
}

export async function getNoteContent({ noteId, accountId }) {
  try {
    const docRef = await db.collection(`accounts/${accountId}/notes`).doc(noteId).get();
    const data = docRef.data();

    // Also get the tags for the note which are stored under a sub collection called tags
    const tagsSnapshot = await db.collection(`accounts/${accountId}/notes/${noteId}/tags`).get();
    const tags = tagsSnapshot.docs.map(tagDoc => tagDoc.data());
    console.log("Retrieved tags for note", noteId, tags)

    // Also get the references for the note which are stored under a sub collection called references
    const referencesSnapshot = await db.collection(`accounts/${accountId}/notes/${noteId}/references`).get();
    // Filter out any references that are to or from a tag
    const references = referencesSnapshot.docs
      .map(referenceDoc => referenceDoc.data())
      .filter(ref => ref.toType !== "tag" && ref.fromType !== "tag");
    
    console.log("Retrieved references for note", noteId, references)

    return {
      editorState: data.editorState,
      description: data.description,
      tags: tags,
      references: references
    };
  } catch (error) {
    console.error("Error retrieving note content:", error);
    return null;
  }
}

export async function retrieveBinnedNotesFromFirestore({accountId, selectedProjectId, limit = 20, lastDoc = null}) {
  try {
    let query = db.collection(`accounts/${accountId}/notes`)
      .where("project", "==", selectedProjectId ? selectedProjectId : '')
      .where("bin", "==", true)
      .orderBy("updated", "desc")
      .orderBy("timestamp", "desc")
      .limit(limit);

    if (lastDoc) {
      query = query.startAfter(lastDoc);
    }

    const snapshot = await query.get();

    const notes = [];
    snapshot.forEach((doc) => {
      const data = doc.data();
      const tags = data.tags ? data.tags : [];
      notes.push({
        id: doc.id,
        noteTitle: data.noteTitle,
        type: data.type ? data.type : 'note',
        project: data.project,
        editorState: data.editorState,
        url: data.url ? data.url : '',
        description: data.description ? data.description : '',
        timestamp: data.timestamp.toDate(),
        bin: data.bin,
        tags: tags,
        updated: data.updated ? data.updated.toDate() : null
      });
    });

    return {
      notes,
      lastVisible: snapshot.docs[snapshot.docs.length - 1],
      hasMore: notes?.length === limit
    };

  } catch (error) {
    console.error("Error retrieving notes from Firestore:", error);
    return { notes: [], lastVisible: null, hasMore: false };
  }
}

export async function newNote({ user, accountId, selectedProjectId }) {   
  if (user && user !== undefined) {
    //console.log("New note attempted with ", user._delegate.uid, accountId)
    try {
      if (!user) {
        console.error('User not authenticated');
        return;
      }
  
      if (!accountId) {
        console.error('Account id is missing');
        return;
      }
    
      // Allow creation only if user is a team member
      const isTeamMember = await checkTeamMembership(accountId, user._delegate.uid);
    
      if (!isTeamMember) {
        console.error('User is not a team member');
        return;
      } else {
        //console.log("teamMember present, proceeding")
      }
    
      const docRef = await db.collection(`accounts/${accountId}/notes`).add({
        noteTitle: 'Untitled note',
        editorState: emptyEditor,
        createdBy: user._delegate.uid,
        type: 'note',
        project: selectedProjectId ? selectedProjectId : '',
        timestamp: new Date(),
        updated: new Date(),
        tags: [],
        bin: false
      });
  
      //console.log('New note created in Firestore!');
      const newNoteId = docRef.id;
      const record = await docRef.get();
      const snapshot = record.data();
  
      return { id: newNoteId, ...snapshot };
  
    } catch (error) {
      console.error("Could not create new note ", error)
    }
  } else {
    //console.log("User not setup yet")
  }
}

export async function addLink({ user, accountId, selectedProjectId, linkTitle, linkAddress, description }) {
  if (user && user !== undefined) {
    
    try {
      // Allow creation only if user is a team member
      const isTeamMember = await checkTeamMembership(accountId, user._delegate.uid);
      
      if (!isTeamMember) {
        console.error('User is not a team member');
        return;
      } else {
        //console.log("teamMember present, proceeding")
      }
    
      const docRef = await db.collection(`accounts/${accountId}/notes`).add({
        type: 'link',
        noteTitle: linkTitle,
        url: linkAddress,
        description: description,
        createdBy: user._delegate.uid,
        project: selectedProjectId ? selectedProjectId : '',
        timestamp: new Date(),
        updated: new Date(),
        tags: [],
        bin: false
      });

      console.log('New link created in Firestore!');
      const newNoteId = docRef.id;
      const record = await docRef.get();
      const snapshot = record.data();

      return { id: newNoteId, ...snapshot };
    } catch (error) {
      console.error("Could not create new link ", error)
    }
  } else {
    console.log("User not setup yet")
  }
}

export async function addUpload({ user, accountId, selectedProjectId, fileName, downloadURL }) {
  console.log("addUpload attempted with ", user, accountId, selectedProjectId, fileName, downloadURL)
  if (user && user !== undefined) {
    
    try {
      // Allow creation only if user is a team member
      const isTeamMember = await checkTeamMembership(accountId, user._delegate.uid);
      
      if (!isTeamMember) {
        console.error('User is not a team member');
        return;
      } else {
        //console.log("teamMember present, proceeding")
      }
    
      // Add the document to Firestore
      const docRef = await db.collection(`accounts/${accountId}/notes`).add({
        type: 'upload',
        originalTitle: fileName,
        noteTitle: fileName,
        url: downloadURL,
        description: '',
        createdBy: user._delegate.uid,
        project: selectedProjectId ? selectedProjectId : '',
        timestamp: new Date(),
        updated: new Date(),
        tags: [],
        bin: false,
        transcriptStatus: 'pending' // Add initial transcription status
      });

      console.log('New upload created in Firestore');
      const record = await docRef.get();
      const snapshot = record.data();

      return { id: docRef.id, ...snapshot };
    } catch (error) {
      console.error("Could not create new upload ", error)
    }
  } else {
    console.log("User not setup yet")
  }
}


function dataURItoBlob(dataURL) {
  const byteString = atob(dataURL.split(',')[1]);
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

export async function uploadFile({ accountId, selectedProjectId, user, fileOrDataURL }) {
  console.log("file upload attempted with ", accountId, user, fileOrDataURL)
  try {
    if (!user) {
      console.error('User not authenticated');
      return;
    }

    if (!accountId) {
      console.error('Account id is missing');
      return;
    }

    // Allow creation only if user is a team member
    const isTeamMember = await checkTeamMembership(accountId, user._delegate.uid);

    if (!isTeamMember) {
      console.error('User is not a team member');
      return;
    } else {
      //console.log("teamMember present, proceeding");
    }

    const storage = getStorage();
    const idForFile = v4()
    const fileName = idForFile + '-' + fileOrDataURL.name
    const fileSrcRef = ref(storage, `users/${user._delegate.uid}/uploads/${fileName}`);
    try {
      const result = uploadBytes(fileSrcRef, fileOrDataURL).then( async (snapshot) => {
        console.log('Uploaded a blob or file!');
        const downloadURL = await getDownloadURL(fileSrcRef);
        console.log('Download URL:', downloadURL);
        return { status: 'success', fileName: fileName, downloadURL: downloadURL };
      });
      return result
    } catch (error) {
      console.error("Could not upload file ", error)
      return { status: 'error', fileName: '', downloadURL: '' };
    }    

  } catch (error) {
    console.error("Could not upload file ", error)
    return { status: 'error', fileName: '', downloadURL: '' };
  }
}

export async function addImageToNote(noteId, accountId, user, image) {
  //console.log("image upload attempted with ", noteId, accountId, user, image)
  try {
    if (!user) {
      console.error('User not authenticated');
      return;
    }

    if (!accountId) {
      console.error('Account id is missing');
      return;
    }

    // Allow creation only if user is a team member
    const isTeamMember = await checkTeamMembership(accountId, user._delegate.uid);

    if (!isTeamMember) {
      console.error('User is not a team member');
      return;
    } else {
      //console.log("teamMember present, proceeding");
    }

    const docRef = await db.collection(`images`).add({
      account: accountId,
      uploadedBy: user._delegate.uid,
      created: new Date(),
      noteId: noteId,
      image: image
    });

    const recordTwo = await docRef.get();
    const snapshotTwo = recordTwo.data();
    //console.log('Image added to note in Firestore!', snapshotTwo);

    return { id: noteId, ...snapshotTwo };

  } catch (error) {
    console.error("Could not add image to note ", error)
  }
}

export async function deleteImage({ accountId, image }) {
  //console.log("image delete attempted with ", image)
  try {
    if (!image) {
      console.error('Valid image value not passed');
      return;
    }

    if (!accountId) {
      console.error('Account id is missing');
      return;
    }

    const results = await db.collection(`images`).where("image", "==", image).get()

    //console.log("results for deletion", results)

    const deletionResults = results.forEach((doc) => {
      doc.ref.delete()
    })

    //console.log('Image deleted from Firestore!', deletionResults);
  } catch (error) {
    console.error("Could not delete image ", error);
  }
}

export async function deleteVersionOfNote({ accountId, noteId, versionId }) {
  //console.log("version delete attempted with ", accountId, noteId, versionId)
  try {
    if (!noteId) {
      console.error('Valid noteId value not passed');
      return;
    }

    if (!accountId) {
      console.error('Account id is missing');
      return;
    }

    if (!versionId) {
      console.error('Version id is missing');
      return;
    }

    const docRef = await db.collection(`accounts/${accountId}/notes/${noteId}/versions`).doc(versionId).delete();

    //console.log('Version deleted from Firestore!', docRef);
  } catch (error) {
    console.error("Could not delete version ", error);
  }
}

export async function retrieveVersionsOfNote({accountId,noteId}) {
  try {
    const snapshot = await db.collection(`accounts/${accountId}/notes/${noteId}/versions`)
    .orderBy("timestamp", "desc")
    .get();

    const versions = [];
    snapshot.forEach((doc) => {
      const data = doc.data();
      // console.log("data", data)
      versions.push({
        id: doc.id,
        noteTitle: data.noteTitle,
        type: data.type,
        project: data.project,
        editorState: data.editorState,
        timestamp: data.timestamp.toDate(),
        bin: data.bin,
        tags: data.tags,
        updated: data.updated ? data.updated.toDate() : null
      });
    });

    // //console.log("Retrieved versions from Firestore:", versions);
    return versions;
  } catch (error) {
    console.error("Error retrieving versions from Firestore:", error);
    return [];
  }
}

export const executeFilteredQuery = async ({ 
  accountId, 
  selectedProjectId, 
  notesFiltered, 
  uploadsFiltered, 
  linksFiltered, 
  userFilters, 
  pendingTag, 
  setFilteredNotes, 
  setSearchFilteredNotes, 
  setCurrentUserNotesFilteredCount,
  tagsFiltered,
}) => {
  const db = database.firestore();
  let query = db.collection(`accounts/${accountId}/notes`)
    .where("project", "==", selectedProjectId ? selectedProjectId : '')
    .where("bin", "!=", true);

  // Add type filters
  console.log("notesFiltered", notesFiltered)
  console.log("uploadsFiltered", uploadsFiltered)
  console.log("linksFiltered", linksFiltered)
  console.log("tagsFiltered", tagsFiltered)
  console.log("pendingTag", pendingTag)
  if (notesFiltered || uploadsFiltered || linksFiltered) {
    const validTypes = [];
    if (!notesFiltered) validTypes.push('note');
    if (!uploadsFiltered) validTypes.push('upload');
    if (!linksFiltered) validTypes.push('link');
    
    if (validTypes.length > 0) {
      query = query.where("type", "in", validTypes);
    }
  }

  // Add user filters
  const activeUserFilters = Object.entries(userFilters)
    .filter(([_, isActive]) => isActive)
    .map(([userId]) => userId);

  if (activeUserFilters.length > 0) {
    query = query.where("createdBy", "in", activeUserFilters);
  }

  // TO DO Add tag filters
  // if (tagsFiltered && pendingTag.length > 0) {
  //   const tagIds = new Set(pendingTag.map(tag => tag.id));
  //   // Get the results first
  //   const results = await query.get();
  //   // Then filter in memory
  //   const filteredDocs = results.docs.filter(doc => {
  //     const noteTags = doc.data().tags || [];
  //     return noteTags.some(tag => tagIds.has(tag.id));
  //   });
  //   // Update the query with the filtered results
  //   query = query.where("__name__", "in", filteredDocs.map(doc => doc.id));
  // }

  // Execute query
  try {
    const snapshot = await query
      .orderBy("updated", "desc")
      .limit(20)
      .get();

    const filteredNotes = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      timestamp: doc.data().timestamp?.toDate(),
      updated: doc.data().updated?.toDate()
    }));

    console.log("filteredNotes within database checking for empty", filteredNotes)
    setSearchFilteredNotes(filteredNotes);
    setCurrentUserNotesFilteredCount(filteredNotes.length);
    
  } catch (error) {
    console.error("Error executing filtered query:", error);
    setSearchFilteredNotes([]);
    setCurrentUserNotesFilteredCount(0);
  }
};

export default async function saveNoteToFirestore({ selectedNote, state, title, pinned, accountId, selectedProjectId, tags, type }) { 
  const id = selectedNote && selectedNote.id ? selectedNote.id : null; 
  let referredType = type ? type : selectedNote.type;
  if (referredType === "") {
    referredType = 'note';
  }

  if (id) {
    const existingDoc = await db.collection(`accounts/${accountId}/notes`).doc(id).get();
    if (existingDoc.exists) {
      try {
        // If the document exists, update specific fields
        const updateData = {
          noteTitle: title,
          editorState: state,
          type: referredType,
          project: selectedProjectId ? selectedProjectId : '',
          updated: new Date(),
          tags: tags ? tags : [],
          pinned: pinned,
          bin: false,
        };

        await db.collection(`accounts/${accountId}/notes`).doc(id).update(updateData);
      } catch (error) {
        console.error("Error saving note to Firestore:", error);
      }
    } else {
      console.error("No document found with id ", id);
    } 
  } else {
    try {
      const newData = {
        noteTitle: title,
        editorState: state,
        project: selectedProjectId ? selectedProjectId : '',
        type: 'note',
        timestamp: new Date(),
        updated: new Date(),
        tags: tags ? tags : [],
        pinned: pinned,
        bin: false,
      };

      await db.collection(`accounts/${accountId}/notes`).add(newData);
    } catch (error) {
      console.error("Error saving note to Firestore:", error);
    }
  }
}


export async function updateLink({ type, id, linkTitle, accountId, selectedProjectId, description, linkAddress, tags, pinned }) { 
  
  if (id) {
    const existingDoc = await db.collection(`accounts/${accountId}/notes`).doc(id).get();
    if (existingDoc.exists) {
      try {
        // If the document exists, update specific fields
        const updateData = {
          noteTitle: linkTitle, // Replace with the new title
          type: type ? type : 'link',
          url: linkAddress ? linkAddress : '',
          description: description ? description : '',
          project: selectedProjectId ? selectedProjectId : '',
          updated: new Date(),
          tags: tags ? tags : [],
          pinned: pinned ? pinned : false,
          bin: false
        };

        // If tags are passed, add them to the update data
        if (tags) {
          updateData.tags = tags;
        }

        await db.collection(`accounts/${accountId}/notes`).doc(id).update(updateData);
        //console.log("Note updated in Firestore!");
      } catch(error) {
        console.error("Error saving link to Firestore:", error);
      }
    } else {
      console.error("No document found with id ", id)
    } 
  } else {
    try {
      // Assuming you have a collection named 'notes'
      const newData = {
        noteTitle: linkTitle,
        project: selectedProjectId ? selectedProjectId : '',
        url: linkAddress ? linkAddress : '',
        description: description ? description : '',
        type: type ? type : 'link',
        timestamp: new Date(),
        updated: new Date(),
        tags: tags ? tags : [],
        bin: false
      };

      // If tags are passed, add them to the new data
      if (tags) {
        newData.tags = tags;
      }

      await db.collection(`accounts/${accountId}/notes`).add(newData);
    } catch (error) {
      console.error("Error saving link to Firestore:", error);
    }
  }
}

export async function binNote(id, accountId) {
  //console.log("Bin note attempted with ", id, accountId)

  if (id) {
    const existingDoc = await db.collection(`accounts/${accountId}/notes`).doc(id).get();
    if (existingDoc.exists) {
      try {
        // If the document exists, update specific fields
        const setValue = await db.collection(`accounts/${accountId}/notes`).doc(id).set({  
          bin: true, // mark as binned
        }, { merge: true });
        const newValues = await db.collection(`accounts/${accountId}/notes`).doc(id).get();
        const newValuesData = newValues.data()

        //console.log("Note now marked as binned in Firestore!", newValues.data());
        return newValuesData.bin
      } catch(error) {
        console.error("Error saving note as binned to Firestore:", error);
      }
    } else {
      console.error("No document found with id ", id)
    } 
  } else {
    //console.log("No id passed to bin note function")
  }
}

export async function pinNote({ noteId, accountId, trueOrFalse }) {
  //console.log("Pin note attempted with ", noteId, accountId)

  if (noteId) {
    const existingDoc = await db.collection(`accounts/${accountId}/notes`).doc(noteId).get();
    if (existingDoc.exists) {
      try {
        // If the document exists, update specific fields
        await db.collection(`accounts/${accountId}/notes`).doc(noteId).set({  
          pinned: trueOrFalse, // mark as pinned
          updated: new Date()
        }, { merge: true });
        const newValues = await db.collection(`accounts/${accountId}/notes`).doc(noteId).get();
        const newValuesData = newValues.data()

        //console.log("Note now marked as pinned in Firestore!", newValues.data());
        return newValuesData.pinned
      } catch(error) {
        console.error("Error saving note as pinned to Firestore:", error);
      }
    } else {
      console.error("No document found with id ", noteId)
    } 
  } else {
    console.log("No id passed to pin note function")
  }
}

export async function unBinNote(id, accountId) {
  //console.log("Un-Bin note attempted with ", id, accountId)

  if (id) {
    const existingDoc = await db.collection(`accounts/${accountId}/notes`).doc(id).get();
    if (existingDoc.exists) {
      try {
        // If the document exists, update specific fields
        await db.collection(`accounts/${accountId}/notes`).doc(id).set({  
          bin: false, // mark as binned
        }, { merge: true });
        const newValues = await db.collection(`accounts/${accountId}/notes`).doc(id).get();
        const newValuesData = newValues.data()

        //console.log("Note now marked as unbinned in Firestore!", newValues.data());
        return newValuesData.bin
      } catch(error) {
        console.error("Error saving note as un-binned to Firestore:", error);
      }
    } else {
      console.error("No document found with id ", id)
    } 
  } else {
    //console.log("No id passed to un-bin note function")
  }
}

export async function deleteNote(noteId, accountId) {
  //console.log("Delete note attempted with ", noteId, accountId)
  try {
    const docRef = db.collection(`accounts/${accountId}/notes`).doc(noteId);
    const snapshot = await docRef.get();

    if (snapshot.exists) {
      await docRef.delete();
      //console.log("Note deleted from Firestore with ID:", noteId);

      // Optionally, you can return the deleted note data if needed
      const deletedNoteData = snapshot.data();
      return { id: noteId, ...deletedNoteData };
    } else {
      console.error("No document found with ID:", noteId);
      return null;
    }
  } catch (error) {
    console.error("Error deleting note from Firestore:", error);
    return null;
  }
}

export async function getNoteCount({ accountId, selectedProjectId }) {
  try {
    // Use the modern Firebase SDK syntax
    const notesRef = collection(db, `accounts/${accountId}/notes`);
    
    // Create query with filters
    const q = query(
      notesRef,
      where("project", "==", selectedProjectId ? selectedProjectId : ''),
      where("bin", "!=", true)
    );
    
    // Get count using the query
    const snapshot = await getCountFromServer(q);
    console.log("Fetched note count is ", snapshot.data().count);
    return snapshot.data().count;
    
  } catch (error) {
    console.error("Error getting note count:", error);
    return 0;
  }
}
