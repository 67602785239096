import { BASE_PRIVATEAPP_URL } from '../../redirects';

export const buildUrl = (accountId, projectId, path = '', params = {}) => {
  const baseUrl = `${BASE_PRIVATEAPP_URL}${accountId}/${projectId}`;
  const queryString = Object.entries(params)
    .filter(([_, value]) => value !== undefined && value !== '')
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return `${baseUrl}${path}${queryString ? `?${queryString}` : ''}`;
};

export const navigationPaths = {
  notes: (accountId, projectId, noteId) => 
    buildUrl(accountId, projectId, '', { noteId }),
  
  notesBin: (accountId, projectId, noteId) => 
    buildUrl(accountId, projectId, '/bin', { noteId }),
  
  boards: (accountId, projectId, boardId) => 
    buildUrl(accountId, projectId, `/tags/b/${boardId}`),
  
  personas: (accountId, projectId, personaId) => 
    buildUrl(accountId, projectId, '/personas', { personaId }),
  
  journeys: (accountId, projectId, journeyId) => 
    buildUrl(accountId, projectId, '/journeys', { journeyId }),
  
  features: (accountId, projectId) => 
    buildUrl(accountId, projectId, '/features'),
  
  pages: (accountId, projectId, pageId) => 
    buildUrl(accountId, projectId, '/pages', { pageId }),
  
  ia: (accountId, projectId, iaId) => 
    buildUrl(accountId, projectId, '/ia', { iaId }),
  
  connections: (accountId, projectId, connectionId) => 
    buildUrl(accountId, projectId, '/connections', { connectionId })
};

export const getNavigationPathByType = (type) => {
  const pathMap = {
    'note': navigationPaths.notes,
    'bin-note': navigationPaths.notesBin,
    'board': navigationPaths.boards,
    'persona': navigationPaths.personas,
    'journey': navigationPaths.journeys,
    'feature': navigationPaths.features,
    'tag': navigationPaths.boards,
    'page': navigationPaths.pages,
    'ia': navigationPaths.ia,
    'connection': navigationPaths.connections
  };

  return pathMap[type] || navigationPaths.notes; // Default to notes if type not found
};