import { useState, useCallback } from 'react';
import Input from '@mui/joy/Input';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import { debounce } from 'lodash';
import { searchUsingText, searchTagsForProject } from '../../../database/search-db';
import { useBoards } from '../../../contexts/boardsContext';
import { useParams } from 'react-router-dom';
import Chip from '@mui/joy/Chip';
import { TagLabelWithColourOnly } from '../../Tags/TagChip';
import { Breadcrumbs, Link } from '@mui/joy';

const results = [
  { title: 'Option 1', projectName: 'Project 1' },
  { title: 'Option 2', projectName: 'Project 2' },
  { title: 'Option 3', projectName: 'Project 3' },
];

const typeMapping = {
  searchItem_tagBoard: 'Board',
  searchItem_boardGroup: 'Group',
  searchItem_tag: 'Tag',
  searchItem_note: 'Note',
  searchItem_persona: 'Persona',
  searchItem_feature: 'Feature',
  searchItem_pageset: 'Set',
  searchItem_page: 'Page',
  searchItem_journey: 'Journey',
  searchItem_sitemap: 'Navigation'
};

const typeOrder = {
  'searchItem_note': 1,
  'searchItem_persona': 2,
  'searchItem_feature': 3,
  'searchItem_tag': 4,
  'searchItem_boardGroup': 5,
  'searchItem_tagBoard': 6,
  'searchItem_pageset': 7,
  'searchItem_page': 8,
  'searchItem_journey': 9,
  'searchItem_sitemap': 10
};

const getSortedSearchResults = (results) => {
  if (!Array.isArray(results)) return [];
  
  return [...results]
    .map(result => ({
      ...result,
      typeLabel: typeMapping[result.type] || "",
    }))
    .sort((a, b) => {
      // First sort by type order
      const typeOrderComparison = (typeOrder[a.type] || 99) - (typeOrder[b.type] || 99);
      if (typeOrderComparison !== 0) {
        return typeOrderComparison;
      }
      // Then sort by title
      return a.title.localeCompare(b.title);
    });
};

function SidePaneSearch() {
  const [inputValue, setInputValue] = useState('');
  const [running, setRunning] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [filteredTagResults, setFilteredTagResults] = useState([]);
  const [tagResults, setTagResults] = useState([]);
  const [tagSearch, setTagSearch] = useState(false)
  const { boards, boardsGroupsTags } = useBoards();

  const { accountId, selectedProjectId } = useParams();

  const handleTypeAheadSearch = useCallback(debounce(async () => {
    if (!running && !isSelecting) {
      setRunning(true);
      try {
        const results = await searchUsingText({ accountId, text: inputValue, project: selectedProjectId });
        const sortedResults = getSortedSearchResults(results);
        setSearchResults(sortedResults);
      } catch (error) {
        console.error("Error during search:", error);
      } finally {
        setRunning(false);
      }
    }
  }, 300), [inputValue, running, isSelecting]);

  const handleHashtagSearch = useCallback(async () => {
    if (!running && !isSelecting) {
      setTagSearch(true);
      setRunning(true);
      try {
        const results = await searchTagsForProject({ accountId, projectId: selectedProjectId });
        // If results.length is greater than 0 then filter through each and use the result.boardId to filter against boards and find the respective board.title, then add this to the result
        const filteredResults = results.map(result => {
          const board = boards.find(board => board.id === result.boardId);
          if (!board) return null;
          const boardGroup = board.boardGroups.find(boardGroup => boardGroup.id === result.groupId);
          return {
            ...result,
            boardTitle: board.title,
            boardGroupTitle: boardGroup?.title
          };
        }).filter(Boolean); // Remove any null results
        setTagResults(filteredResults); // original
        setFilteredTagResults(filteredResults); // to play with
      } catch (error) {
        console.error("Error during search:", error);
      } finally {
        setRunning(false);
      }
    }
  }, [inputValue, running, isSelecting]);

  console.log("filteredTagResults", filteredTagResults)

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Input 
          placeholder="Search across project" 
          value={inputValue}
          onChange={(event) => {
            const value = event.target.value;
            if (value === '#') {
              console.log("hashtag only");
              if (tagResults.length > 0) {
                setTagSearch(true);
                setFilteredTagResults(tagResults); // Reset to show all tags
              } else {
                handleHashtagSearch();
              }
            } else if (value.startsWith('#')) {
              console.log("filtering tag results");
              const searchTerm = value.slice(1).toLowerCase();
              const filtered = tagResults.filter(result => 
                result.title.toLowerCase().includes(searchTerm)
              );
              setTagSearch(true);
              setFilteredTagResults(filtered);
            } else {
              console.log("not tag search");
              setTagSearch(false);
              if (value.length > 2) {
                handleTypeAheadSearch();
              }
            }
            setInputValue(value);
          }}
        />
      </Box>
      { tagSearch ? filteredTagResults.map((option, index) => (
        <SearchResultListing key={index}>
          <TagLabelWithColourOnly tag={option} />
          <Typography sx={{ ml: 1 }} level="body-xs">
            {option.boardTitle} - {option.boardGroupTitle}
          </Typography>
        </SearchResultListing>
      )) : searchResults.map((option, index) => (
        <SearchResultListing key={index}>
          { <span>{option.title}</span> }
          <Chip 
            size="sm" 
            variant="soft"
            sx={{ ml: 1 }}
          >
            {option.typeLabel}
          </Chip>
        </SearchResultListing>
      ))}
    </Box>
  );
}

function SearchResultListing({ children, index }) {
  return (
    <AutocompleteOption key={index}>
      <Typography 
        sx={{ 
          lineHeight: 1.25, 
          fontSize: 12, 
          pt: 1.25, 
          pl: 1.25,
          pr: .5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        { children }
      </Typography>
    </AutocompleteOption>
  )
}

export default SidePaneSearch;
