import * as React from 'react';
import Stack from '@mui/joy/Stack';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import NoteMoreBinOptions from './NoteMoreBinOptions';
import { useNotes } from '../../contexts/notesContext';

export default function BinnedNotesPaneHeader({ noteToDisplay }) {

  const { selectedNote } = useNotes();

  return (
    <>
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        borderBottom: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'background.body', // Ivory: FFFFF0, Lavender: F4F1F8
      }}
      py={{ xs: 2, md: 2 }}
      px={{ xs: 1, md: 2 }}
    >
      <Stack direction="row" spacing={{ xs: 1, md: 2 }} alignItems="center">
        <Typography
          fontWeight="lg"
          fontSize="lg"
          component="h2"
          noWrap>
            {selectedNote?.noteTitle ? selectedNote?.noteTitle : 'Untitled'}
        </Typography>
      </Stack>

      <Stack spacing={1} direction="row" alignItems="center">
        {/* <IconButton size="sm" variant="plain" color="neutral">
          <MoreVertRoundedIcon />
        </IconButton>
         */}
         <Box>
          <NoteMoreBinOptions noteToDisplay={noteToDisplay} />
         </Box>
      </Stack>
    </Stack>
    </>
  );
}