import * as React from 'react';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import { getNavigationPathByType } from '../../../utils/navigation';
import { useParams, useNavigate, Link } from 'react-router-dom';

export default function ExistingReferencesTabs({ existingReferences }) {
  const [index, setIndex] = React.useState(0);
  const { accountId, projectId } = useParams();
  const navigate = useNavigate();

  // Create a to and from array using existingReferences.direction == "to" or "from" as the filter
  const toReferences = existingReferences.filter(reference => reference.direction == "to");
  const fromReferences = existingReferences.filter(reference => reference.direction == "from");

  console.log("toReferences", toReferences, "length:", toReferences.length);
  console.log("fromReferences", fromReferences, "length:", fromReferences.length);

  return (
    <Box sx={{ flexGrow: 1, pb: 2, overflowX: 'hidden' }}>
      <Tabs
        aria-label="Pipeline"
        value={index}
        variant="plain"
        size="sm"
        onChange={(event, value) => setIndex(value)}
      >
        <TabList
          sx={{
            pt: 1,
            justifyContent: 'center',
            [`&& .${tabClasses.root}`]: {
              flex: 'initial',
              bgcolor: 'transparent',
              '&:hover': {
                bgcolor: 'transparent',
              },
              [`&.${tabClasses.selected}`]: {
                color: 'primary.plainColor',
                '&::after': {
                  height: 2,
                  borderTopLeftRadius: 3,
                  borderTopRightRadius: 3,
                  bgcolor: 'primary.500',
                },
              },
            },
          }}
        >
          <Tab indicatorInset>
            References
          </Tab>
          <Tab indicatorInset>
            Referenced from
          </Tab>          
        </TabList>
        <Box
          sx={(theme) => ({
            '--bg': theme.vars.palette.background.surface,
            background: 'var(--bg)',
            boxShadow: '0 0 0 100vmax var(--bg)',
            clipPath: 'inset(0 -100vmax)',
          })}
        >
          <TabPanel value={0}>
            {fromReferences.length > 0 ? (
              fromReferences.map((reference) => (
                <Link 
                  key={reference?.connection?.fromData?.id}
                  to={getNavigationPathByType(reference?.connection?.toType)(
                    accountId,
                    reference?.connection?.toData?.projectId || reference?.connection?.toData?.project,
                    reference?.connection?.toType === "tag" 
                      ? reference?.connection?.toData?.boardId 
                      : reference?.connection?.toData?.id
                  )}
                  style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
                >
                  {reference?.connection?.title}
                </Link>
              ))
            ) : (
              <div>No references to other documents found.</div>
            )}
          </TabPanel>
          <TabPanel value={1}>
            {toReferences.length > 0 ? (
              toReferences.map((reference) => (
                <Link 
                  key={reference?.connection?.toData?.id}
                  to={getNavigationPathByType(reference?.connection?.fromType)(
                    accountId,
                    reference?.connection?.fromData?.projectId || reference?.connection?.fromData?.project,
                    reference?.connection?.fromType === "tag"
                      ? reference?.connection?.fromData?.boardId
                      : reference?.connection?.fromData?.id
                  )}
                  style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
                >
                  {reference?.connection?.title}
                </Link>
              ))
            ) : (
              <div>No other documents are currently referencing this document.</div>
            )}
          </TabPanel>
        </Box>
      </Tabs>
    </Box>
  );
}
