import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { closeSidebar } from '../../utils/utils';
import { useAuth } from '../../../authContext'; 
import Tooltip from '@mui/joy/Tooltip';
import SideNavigation from '../Navigations/Side';
import UpgradeSidebar from '../Upgrade/Sidebar';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import { IncitefulLogo } from './../../../PublicSite/Components/IncitefulLogo/index';
import ScheduleIcon from '@mui/icons-material/Schedule';
import GroupsIcon from '@mui/icons-material/Groups';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_PRIVATEAPP_URL } from '../../../redirects';

export default function Sidebar() {

  const [isScrolled, setIsScrolled] = React.useState(false);
  const navigate = useNavigate();
  const { accountId, selectedProjectId } = useParams();
  const { currentUser } = useAuth();
  // first check if the upgradeHidden cookie is set, if not then set it to false
  const [upgradeHidden, setUpgradeHidden] = React.useState(document.cookie.includes('upgradeHidden=true'));

  React.useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setIsScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: {
          xs: 'fixed',
          md: 'sticky',
        },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s, padding-bottom 0.2s ease-in-out',
        // zIndex: 10000,
        minHeight: 'calc(100dvh - var(--Header-height))',
        width: 'var(--Sidebar-width)',
        top: 0,
        px: 2,
        pt: 1.5,
        pb: 4,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        // pb: isScrolled ? 3 : 10,
        // borderRight: '1px solid',
        // borderColor: 'divider',
        backgroundColor: '#F4F1F8', // Ivory: FFFFF0, Lavender: F4F1F8
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          // zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: 'calc(100dvh - var(--Header-height))',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      
      {/*  Relocated search to FullWidthHeader */}
      {/* <Input size="sm" startDecorator={<SearchRoundedIcon />} placeholder="Search" /> */}
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        {/* Insert side nav */}
        <SideNavigation closeSidebar={closeSidebar} />

        <Divider />

        <List
          size="sm"
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
            '--List-gap': '8px',
            mb: 1.5,
          }}
        >
          <ListItem>
            <ListItemButton onClick={() => { navigate(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/pinned') }}>
              <BookmarkAddedIcon />
              Pinned
            </ListItemButton>
          </ListItem>
          { currentUser && currentUser.plan === 'team' && (
          <ListItem>
            <ListItemButton onClick={() => { navigate(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/account?selectedTab=team') }}>
              <GroupsIcon />
              Team
            </ListItemButton>
          </ListItem>
          )}
          <ListItem>
            <ListItemButton onClick={() => { navigate(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/account?selectedTab=settings') }}>
              <SettingsRoundedIcon />
              Settings
            </ListItemButton>
          </ListItem>
        </List>

        {/* Insert upgrade module */}

        { !upgradeHidden && (
        <Box>
          <UpgradeSidebar setUpgradeHidden={setUpgradeHidden} />
        </Box>
        )}

        <Box sx={{ mt: 2, ml: 0 }}>
          <IncitefulLogo /> 
          <Typography sx={{ color: '#50174E', fontSize: 10, fontWeight: '700', mt: -1, ml: 0 }}>BETA</Typography>
        </Box>

      </Box>

      {/* Relocated account functions to fullWidthHeader */}
      
    </Sheet>
  );
}