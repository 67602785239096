import { useState, useEffect } from "react";
import { Box, Button, Stack } from "@mui/joy";
import NoNotePresent from "../../NoNotePresent";
import BinnedNotesHeader from "../../../MyNotes/BinnedNotesHeader";
import NotesPaneHeaderView from "../../NotesPaneHeaderView";
import ViewPrivateItemInput from "../../../ViewPrivateItemInput";
import Editor from "../../../Editor";
import Loading from "../../../Snackbar/Loading";
import { useNotes } from "../../../../contexts/notesContext";
import NoteTitle from "../../NoteTitle";
import BinnedNotesPaneHeader from './../../BinnedNotesPaneHeader';

function NoteLayout({showSidePane, content}) {
  const { notes, binPage, selectedNote, passedPasswordCheck, allowEditing } = useNotes();
  const noteToDisplay = content || selectedNote;

  return (
    <Box
      sx={{
        flex: 1,
        flexDirection: 'column',
        borderRadius: {
          lg: 0,
          xs: 0,
        },
        backgroundColor: '#fff', // Ivory: FFFFF0, Lavender: F4F1F8,
        boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
        my: {
          lg: 0,
          xs: 0,
        },
        maxWidth: {
          xs: '100vw'
        },
        minHeight: '92dvh', // very sensitive value to the height of the window causing a scroll
        maxHeight: '92dvh',
        overflow: 'auto',
      }}
    >
      { notes?.length === 0 ?
        <><NoNotePresent /></>
      :
      binPage ? 
        <>
          <BinnedNotesPaneHeader noteToDisplay={content} />
          <NoteTitle mode="view" noteToDisplay={content} />
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              minHeight: 0,
              px: 2,
              py: 2,
              flexDirection: 'column-reverse',
            }}
          >
            {/* <Stack spacing={2} direction="row" justifyContent="flex-start" sx={{ flex: 1, display: 'flex', background: 'red' }}> */}
            <Stack spacing={2} justifyContent="flex-end">
              { 
              noteToDisplay.private 
              ?
                !passedPasswordCheck 
                  ?
                    <ViewPrivateItemInput content={content} />
                  : 
                    <Editor editable={allowEditing} contentSource={noteToDisplay} />
              : 
                <Editor editable={allowEditing} contentSource={noteToDisplay} />
              }
              <Loading />
            </Stack>
          </Box>
        </>
      : 
        <>
          <NotesPaneHeaderView content={content} />
          <NoteTitle mode="view" noteToDisplay={content} />
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              minHeight: 0,
              px: .5,
              py: 0,
              flexDirection: 'column-reverse',
            }}
          >
            {/* <Stack spacing={2} direction="row" justifyContent="flex-start" sx={{ flex: 1, display: 'flex', background: 'red' }}> */}
            <Stack spacing={2} justifyContent="flex-end"> 
              { 
              noteToDisplay.private ?
                !passedPasswordCheck ?
                  <ViewPrivateItemInput content={content} />
                :
                  <Editor editable={allowEditing} contentSource={noteToDisplay} />
              :
                <Editor editable={allowEditing} contentSource={noteToDisplay} />
              }
              <Loading />
            </Stack>
          </Box>
        </>
      }
    </Box>
  );
}

export default NoteLayout
