import React, { useEffect, useRef, useState } from 'react';
import { Popper } from '@mui/base/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { createFilterOptions } from '@mui/joy/Autocomplete';
import AutocompleteListbox from '@mui/joy/AutocompleteListbox';
import Chip from '@mui/joy/Chip';
import Badge from '@mui/joy/Badge';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import Tabs from '@mui/joy/Tabs';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import CloseIcon from '@mui/icons-material/Close';
import Add from '@mui/icons-material/Add';
import { IconButton, Tooltip } from '@mui/joy';
import LabelIcon from '@mui/icons-material/Label';
import { useParams } from 'react-router-dom';
import { useBoards } from '../../../contexts/boardsContext';
import TagChip from '../../Tags/TagChip';
import { useTags } from '../../../contexts/tagsContext';
import { useNotes } from '../../../contexts/notesContext';
import { useAuth } from '../../../../authContext';
import TagSwitch from '../TagSwitch';
import { Accordion, AccordionGroup } from '@mui/joy';
import Input from '@mui/joy/Input';
import AccordionDetails, {
  accordionDetailsClasses,
} from '@mui/joy/AccordionDetails';
import AccordionSummary, {
  accordionSummaryClasses,
} from '@mui/joy/AccordionSummary';
import CreateConnection from '../../Connections/CreateConnection';
import { addConnectionToFirestore } from '../../../database/connections-db';
import FeedbackSnackbar from '../../Snackbar';

const textColourStrength = 500

export default function TagPicker({
  noteToDisplay, 
  handleUpdateTags, 
  pendingTag, 
  setPendingTag, 
  selectedTags, 
  mode, 
  tooltipTitle = `Tag & Link`,
  showSnackbar = false,
  setShowSnackbar,
  snackbarSeverity = 'neutral',
  setSnackbarSeverity,
  snackbarMsg = '',
  setSnackbarMsg,
  existingReferences = [],
}) {
    
    const { tags, handleAddNewTag } = useTags()
    const { selectedNote, checkNoteEditPermissionsWithoutEditor } = useNotes()
    const { boards, boardsGroupsTags, handleFetchBoards, handleFetchBoardsGroupsTags } = useBoards()  // Add these methods
    const { accountId, selectedProjectId } = useParams()
    const [ selectedTagBoard, setSelectedTagBoard ] = React.useState()
    const [ selectedGroupBoards, setSelectedGroupBoards ] = React.useState([])
    const [ selectedGroupBoard, setSelectedGroupBoard ] = React.useState()
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const [newTagInput, setNewTagInput] = React.useState('');
    const [searchTerm, setSearchTerm] = React.useState('');
    const [expandedAccordions, setExpandedAccordions] = React.useState({});
    const inputRef = useRef(null);
    const [open, setOpen] = React.useState(false);
    const popperRef = useRef(null);
    const { currentUser } = useAuth();
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const [shouldFocusInput, setShouldFocusInput] = useState(false);

    // Connect tag
    const [source, setSource] = useState('');
    const [sourceId, setSourceId] = useState('');
    const [sourceTitle, setSourceTitle] = useState('');
    const [sourceType, setSourceType] = useState('');
    const [target, setTarget] = useState('');
    const [targetId, setTargetId] = useState('');
    const [targetTitle, setTargetTitle] = useState('');
    const [targetType, setTargetType] = useState('');
    const [comment, setComment] = useState('');

    const id = open ? 'tag-picker-popper' : undefined;

    const handleClickDelegator = (event) => {
      setPendingTag(selectedTags);        
      setOpen(true);
      setAnchorEl(event.currentTarget);
      setShouldFocusInput(true);
    };

    const handleClose = () => {
      setSearchTerm('');
      setOpen(false);
      setAnchorEl(null);
      setShouldFocusInput(false);
    };

    const saveClose = () => {
      handleClose()
      if (anchorEl) {
          anchorEl.focus();
      }
      setAnchorEl(null);
    };

    const handleSaveClose = () => {
      handleUpdateTags()
      saveClose()
    }

    const handleSelectedTagGroup = () => {
      //console.log("handleSelectedTagGroup called")
      const selectedBoards = boardsGroupsTags.filter(board => board.id === selectedTagBoard)
      //console.log("selectedBoards", selectedBoards)
      if (
          selectedBoards &&
          selectedBoards.length > 0 &&
          selectedBoards[0].boardGroups &&
          selectedBoards[0].boardGroups.length > 0
      ) {
          setSelectedGroupBoards(selectedBoards[0].boardGroups);
          setSelectedGroupBoard(selectedBoards[0].boardGroups[0].id);
      }
    }

    const handleCreateConnection = async () => {
      try {
        const result = await addConnectionToFirestore({ 
          accountId, 
          project: selectedProjectId, 
          user: currentUser,
          connection: { 
            fromType: sourceType, 
            fromId: sourceId, 
            fromData: source,
            fromTitle: sourceTitle,
            toType: target && target.type === "searchItem_note" 
              ? "note" 
              : target.type === "searchItem_page" ? 'page'
              : target.type === "searchItem_tag" ? 'tag'
              : target.type === "searchItem_board" ? 'board'
              : target.type === "searchItem_boardGroup" ? 'boardGroup'
              : target.type === "searchItem_sitemap" ? 'sitemap'
              : target.type === "searchItem_connection" ? 'connection'
              : target.type === "searchItem_search" ? 'search'
              : 'unknown_type', 
            toId: target && target.type === "searchItem_note" ? target.noteId : target.id, 
            toTitle: target.title,
            toData: target,
            comment: comment,
          }
        });
        // Close the tag picker
        setOpen(false);
        setAnchorEl(null);
        setShouldFocusInput(false);
        // Show the snackbar
        setShowSnackbar(true)
        setSnackbarSeverity('success')
        setSnackbarMsg('Connection created')
      } catch (error) {
        console.error("Error creating connection", error)
        setShowSnackbar(true)
        setSnackbarSeverity('error')
        setSnackbarMsg('Error creating connection')
      }
    }

    const handleFilterView = (view) => {
    }

    const handleSearch = (event) => {
      const term = event.target.value;
      setSearchTerm(term);

      const newExpandedAccordions = {};
      const termLower = term.toLowerCase();

      boards.forEach(board => {
          let shouldExpandBoard = false;
          
          if (board.title.toLowerCase().includes(termLower)) {
              shouldExpandBoard = true;
          }

          board.boardGroups.forEach(group => {
              let shouldExpandGroup = false;
              
              if (group.title.toLowerCase().includes(termLower)) {
                  shouldExpandBoard = true;
                  shouldExpandGroup = true;
              }

              // Check if any tags in this group match the search term
              const hasMatchingTags = group.tags.some(tag => 
                  tag.title === term || tag.title.toLowerCase().includes(termLower)
              );

              if (hasMatchingTags || term.length > 0) {
                  shouldExpandBoard = true;
                  shouldExpandGroup = true;
              }

              if (shouldExpandGroup) {
                  newExpandedAccordions[group.id] = true;
              }
          });

          if (shouldExpandBoard) {
              newExpandedAccordions[board.id] = true;
          }
      });

      setExpandedAccordions(newExpandedAccordions);
    };

    // Add this function before the return statement
    const hasMatchingTags = (searchTerm) => {
      const termLower = searchTerm.toLowerCase();
      return boards.some(board =>
          board.boardGroups.some(group =>
              group.tags.some(tag => 
                  tag.title === searchTerm || tag.title.toLowerCase().includes(termLower)
              )
          )
      );
    };

    React.useEffect(() => {
        // If boards.length and boardsGroupsTags.length is greater than 0, find the boardGroups from boardsGroupsTags
        //console.log("useEffect called", boardsGroupsTags)
        if (boards.length > 0 && boards[0] && boards[0].id) {
          setSelectedTagBoard(boards[0].id)
        }
        if (boards.length > 0 && boardsGroupsTags.length > 0) {
            handleSelectedTagGroup()
        }
    }, [boards, boardsGroupsTags, selectedTagBoard])

    React.useEffect(() => {
        //console.log("selectedGroupBoard called", selectedGroupBoard)
    }, [selectedGroupBoard])

    React.useEffect(() => {
        // Grab the latest groups for the selected board
        //console.log("selectedTagBoard called", selectedTagBoard)
    }, [selectedTagBoard])

    React.useEffect(() => {
      // console.log("selectedTags changed", selectedTags)
        setPendingTag(selectedTags)
    }, [selectedTags])

    React.useEffect(() => {
      // console.log("tags changed", tags)
    }, [tags])
  
    React.useEffect(() => {
      if (boards.length > 0 && boardsGroupsTags.length > 0) {
        setSelectedTagBoard(boards[0].id);
        if (boards[0].boardGroups && boards[0].boardGroups.length > 0) {
          setSelectedGroupBoards(boards[0].boardGroups);
          setSelectedGroupBoard(boards[0].boardGroups[0].id);
        }
      }
    }, [boards, boardsGroupsTags]);

    useEffect(() => {
      if (searchTerm.length === 0) {
        setExpandedAccordions({});
      }
    }, [searchTerm]);

    // Add this new useEffect
    React.useEffect(() => {
        const newExpandedAccordions = {};
        
        if (boards && Array.isArray(boards)) {  // Add safety check
            boards.forEach(board => {
                if (board && board.boardGroups && Array.isArray(board.boardGroups)) {  // Add safety check
                    board.boardGroups.forEach(group => {
                        if (group && group.tags && Array.isArray(group.tags)) {  // Add safety check
                            group.tags.forEach(tag => {
                                if (selectedTags && Array.isArray(selectedTags) && 
                                    selectedTags.some(selectedTag => selectedTag?.id === tag?.id)) {
                                    newExpandedAccordions[board.id] = true;
                                    newExpandedAccordions[group.id] = true;
                                }
                            });
                        }
                    });
                }
            });
        }
        
        setExpandedAccordions(newExpandedAccordions);
    }, [selectedTags, boards]);

    // Add this new useEffect
    useEffect(() => {
        if (shouldFocusInput) {
            const timer = setTimeout(() => {
                const inputElement = document.querySelector('#tag-search-input');
                if (inputElement) {
                    inputElement.focus();
                }
                setShouldFocusInput(false);
            }, 100);

            return () => clearTimeout(timer);
        }
    }, [shouldFocusInput]);

    useEffect(() => {
      // TO DO set default values for source, sourceId, sourceTitle, sourceType
      if (noteToDisplay) {
        setSource(noteToDisplay)
        setSourceId(noteToDisplay.id)
        setSourceTitle(noteToDisplay.noteTitle)
        setSourceType(noteToDisplay.type)
      }
    }, [noteToDisplay])

    return (
      <React.Fragment>
        <Box className="tag-picker-container" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', p: 0, m: 0, ml: .9 }}>
            <Tooltip 
                title={
                    (selectedTags.length > 0 || existingReferences.length > 0) ? (
                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            gap: 0.5,
                            maxWidth: 350,
                            '& > *': { width: '100%' }
                        }}>
                            {selectedTags.map((tag) => (
                                <TagChip 
                                    key={tag.id} 
                                    tag={tag} 
                                    textColourStrength={textColourStrength}
                                    size="sm"
                                />
                            ))}
                            {existingReferences.map((ref) => (
                                <Chip
                                    key={ref.id}
                                    size="sm"
                                    variant="soft"
                                    sx={{ border: 'none', px: 1.5, pr: 2, py: .5, borderRadius: 6, fontWeight: 600 }} 
                                >
                                    {ref.connection.title}
                                </Chip>
                            ))}
                        </Box>
                    ) : tooltipTitle
                } 
                variant="outlined" 
                color="neutral" 
                arrow
            >
                <IconButton 
                  sx={{ mt: .9 }} 
                  onClick={handleClickDelegator} 
                  size="sm" 
                  variant="plain" 
                  color="neutral"
                >
                    <Badge 
                      size="xs" 
                      badgeContent={selectedTags.length + existingReferences.length} 
                      sx={{ fontSize: '10px' }} 
                      color="neutral" 
                      variant="soft"
                    >
                      <LabelIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
            {/* TO DO Insert Choose tags if selectedTags is empty */}
            {/* { mode !== 'filter' &&
              <List
                  size="sm"
                  sx={{ p: 0, m: 0, mt: .3, flex: 1, flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }}
              >
              {selectedTags && selectedTags.map((selectedTag) => (
                <ListItem
                    key={selectedTag.id}
                    sx={{
                      '&:hover': {
                        // Define styles for hover state
                        cursor: 'pointer',
                        opacity: .8
                      },
                      '&:active': {
                        // Define styles for active (click) state
                      },
                    }}
                    // sx={{
                    //   fontWeight: 600,
                    //   backgroundColor: selectedTag.colour,
                    //   color: '#fff',
                    // }}
                >
                    <TagChip tag={selectedTag} textColourStrength={textColourStrength} />
                </ListItem>
                ))}
              </List>
            } */}
        </Box>
        <Popper 
          id={id} 
          style={{ zIndex: 1500 }} 
          open={open} 
          anchorEl={anchorEl} 
          placement="bottom-start"
          ref={popperRef}
          onTransitionEnd={() => setIsPopperOpen(true)}
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Sheet
              variant="outlined"
              sx={(theme) => ({
                width: 420,
                maxHeight: 560,
                boxShadow: 'md',
                borderRadius: '6px',
                overflow: 'auto',
                bgcolor: '#F4F1F8',
                position: 'relative',
              })}
            >
              <Tabs aria-label="tabs" size="sm" defaultValue={0} sx={{ bgcolor: 'transparent' }}>
                <TabList
                  disableUnderline
                  sx={{
                    pt: 1,
                    px: 1.1,
                    gap: 0.5,
                    mb: 1.75,
                    // borderRadius: 'xl',
                    bgcolor: '#F4F1F8',
                    [`& .${tabClasses.root}[aria-selected="true"]`]: {
                      boxShadow: 'sm',
                      bgcolor: 'background.surface',
                    },
                  }}
                >
                  <Tab sx={{ borderRadius: 7 }} disableIndicator>Tag</Tab>
                  <Tab sx={{ borderRadius: 7 }} disableIndicator>Link</Tab>
                </TabList>
                <TabPanel value={0} sx={{ my: 0, mx: 1.5, p: 0 }}>
                  <Input 
                    id="tag-search-input"
                    ref={inputRef}
                    size="sm"
                    sx={{ mb: 1.25 }} 
                    placeholder="Search group/tag name" 
                    value={searchTerm}
                    onChange={handleSearch}
                    endDecorator={ searchTerm && searchTerm.length > 0 && 
                      <IconButton 
                        sx={{ backgroundColor: 'transparent' }} 
                        variant="plain" 
                        size="sm"
                      >
                          <CloseIcon onClick={() => {
                              setSearchTerm('');
                              // This will reset to the initial state based on selectedTags
                              setExpandedAccordions(prevState => {
                                  const newState = {};
                                  boards.forEach(board => {
                                      board.boardGroups.forEach(group => {
                                          group.tags.forEach(tag => {
                                              if (selectedTags.some(selectedTag => selectedTag.id === tag.id)) {
                                                  newState[board.id] = true;
                                                  newState[group.id] = true;
                                              }
                                          });
                                      });
                                  });
                                  return newState;
                              });
                          }} />
                        </IconButton>
                    }
                    onKeyDown={(e) => {
                      if (e.key === 'Backspace') {
                        setExpandedAccordions({});
                      }
                    }}
                  />
                  <AccordionGroup 
                    variant="plain"
                    size="sm"
                    transition="0.2s"
                    sx={{
                      width: '100%',
                      maxWidth: '100%',
                      borderRadius: 'md',
                      [`& .${accordionDetailsClasses.content}.${accordionDetailsClasses.expanded}`]:
                        {
                          paddingBlock: '.5rem',
                        },
                      [`& .${accordionSummaryClasses.button}`]: {
                        paddingBlock: '.5rem',
                      },
                    }}
                  >
                    {boards && boards.length > 0 && boards.map((board) => (
                      <Accordion 
                        key={board.id} 
                        value={board.id}
                        expanded={expandedAccordions[board.id] || false}
                        onChange={(event, expanded) => {
                            setExpandedAccordions(prev => ({...prev, [board.id]: expanded}));
                        }}
                      >
                        <AccordionSummary>{board.title}</AccordionSummary>
                        <AccordionDetails>
                          <AccordionGroup
                            variant="plain"
                            size="sm"
                            transition="0.2s"
                            sx={{
                              width: '100%',
                              borderRadius: 'md',
                              [`& .${accordionDetailsClasses.content}.${accordionDetailsClasses.expanded}`]:
                                {
                                  paddingBlock: '.5rem',
                                },
                              [`& .${accordionSummaryClasses.button}`]: {
                                paddingBlock: '.5rem',
                              },
                            }}
                          >
                            {board.boardGroups && board.boardGroups.length > 0 && board.boardGroups.map((group) => (
                              <Accordion 
                                key={group.id} 
                                value={group.id}
                                expanded={expandedAccordions[group.id] || false}
                                onChange={(event, expanded) => {
                                    setExpandedAccordions(prev => ({...prev, [group.id]: expanded}));
                                }}
                              >
                                <AccordionSummary sx={{ backgroundColor: group.colour[100], '&:hover': { backgroundColor: group.colour[500] } }}>{group.title}</AccordionSummary>
                                <AccordionDetails>
                                  <List>
                                    {group.tags
                                        .filter(tag => {
                                            const termLower = searchTerm.toLowerCase();
                                            // Check for exact match first, then case-insensitive match
                                            return tag.title === searchTerm || tag.title.toLowerCase().includes(termLower);
                                        })
                                        .map((tag) => (
                                          <ListItem key={tag.id}>
                                            <TagSwitch
                                              tag={tag}
                                              textColourStrength={textColourStrength}
                                              selected={pendingTag.some((t) => t.id === tag.id)}
                                              onChange={(event) => {
                                                if (event.target.checked) {
                                                  setPendingTag(prev => {
                                                    const newTags = [...prev, tag];
                                                    return newTags;
                                                  });
                                                } else {
                                                  setPendingTag(prev => {
                                                    const newTags = prev.filter((t) => t.id !== tag.id);
                                                    return newTags;
                                                  });
                                                }
                                              }}
                                            />
                                          </ListItem>
                                        ))}
                                    {searchTerm && !hasMatchingTags(searchTerm) && (
                                        <ListItem>
                                            <Button
                                                size="sm"
                                                variant="outlined"
                                                color="neutral"
                                                startDecorator={<Add />}
                                                onClick={async () => {
                                                    try {
                                                        // Create the new tag
                                                        const newTag = await handleAddNewTag({ 
                                                            name: searchTerm,
                                                            boardId: board.id,
                                                            groupId: group.id
                                                        });
                                                        
                                                        // Refresh the boards data to include the new tag
                                                        await Promise.all([
                                                            handleFetchBoards(),
                                                            handleFetchBoardsGroupsTags()
                                                        ]);
                                                        
                                                        // Format the tag object to match the expected structure
                                                        const formattedTag = {
                                                            id: newTag.id,
                                                            boardId: board.id,
                                                            groupId: group.id,
                                                            accountId: accountId,
                                                            projectId: selectedProjectId,
                                                            tagId: newTag.id,
                                                            title: newTag.title,
                                                            colour: newTag.colour,
                                                            references: []
                                                        };
                                                        
                                                        // Create new array with existing pending tags plus new formatted tag
                                                        const updatedTags = [...pendingTag, formattedTag];
                                                        
                                                        // Update pending tags
                                                        setPendingTag(updatedTags);
                                                        
                                                        // Call handleUpdateTags with the updated tags array
                                                        handleUpdateTags(updatedTags);
                                                        
                                                        // Clear search term and close the picker
                                                        setSearchTerm('');
                                                        setOpen(false);
                                                        setAnchorEl(null);
                                                        setShouldFocusInput(false);
                                                    } catch (error) {
                                                        console.error('Error creating new tag:', error);
                                                        // Optionally add error handling UI here
                                                    }
                                                }}
                                                sx={{ width: '100%', justifyContent: 'flex-start' }}
                                            >
                                                Create "{searchTerm}"
                                            </Button>
                                        </ListItem>
                                    )}
                                  </List>
                                </AccordionDetails>
                              </Accordion>
                            ))}
                          </AccordionGroup>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </AccordionGroup>
                  <Box sx={{ position: 'sticky', bottom: 0, background: '#F4F1F8', width: '95%' }}>
                    <Button onClick={() => handleSaveClose()} size="sm" sx={{ my: 1.5, mx: 1, width: '100%' }}>Apply</Button>
                  </Box>
                </TabPanel>
                <TabPanel value={1} sx={{ my: 0, mx: 1.5, p: 0 }}>
                  <CreateConnection
                    mode="sourceKnown"
                    source={source}
                    setSource={setSource}
                    sourceTitle={sourceTitle}
                    setSourceTitle={setSourceTitle}
                    target={target}
                    setTarget={setTarget}
                    targetTitle={targetTitle}
                    setTargetTitle={setTargetTitle}
                    targetType={targetType}
                    setTargetType={setTargetType}
                    comment={comment}
                    setComment={setComment}
                    handleCreateConnection={handleCreateConnection}
                    existingReferences={existingReferences}
                  />
                  {/* only show this if target is not an empty string */}
                  {target !== "" && (
                    <Box sx={{ position: 'sticky', bottom: 0, background: '#F4F1F8' }}>
                      <Button sx={{ my: 1.5, mx: 0, width: '100%' }} size="sm" onClick={() => handleCreateConnection() } type="submit">Create</Button>
                    </Box>
                  )}
                </TabPanel>
              </Tabs>
            </Sheet>
          </ClickAwayListener>
        </Popper>
      </React.Fragment>
  );
  }

