import React, { useEffect, useState, useCallback } from 'react'
import { Box, Chip, IconButton, Typography, styled } from '@mui/joy';
import Autocomplete from '@mui/joy/Autocomplete';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { retrieveAll, searchUsingText } from '../../database/search-db';
import { useAuth } from '../../../authContext'; 
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { BASE_PRIVATEAPP_URL } from '../../../redirects';
import { useProjects } from '../../contexts/projectsContext';
import { useLoading } from '../../contexts/loadingContext';
import { matchSorter } from 'match-sorter';
import ClearIcon from '@mui/icons-material/Clear';
import { navigationPaths } from '../../utils/navigation';

// TO DO limit by projectId and filters like notes, tags, etc.
export default function Search({ linkTitle, size = "sm", setLinkTitle, mode, setValue, url, setUrl = () => console.log(), value }) {

  const navigate = useNavigate();
  const { accountId, selectedProjectId } = useParams();
  const { currentUser } = useAuth();
  const { accountProjects, selectedProject } = useProjects();
  const { running, setRunning } = useLoading();
  const [open, setOpen] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [projectTitleLookup, setProjectTitleLookup] = React.useState({});
  const [project, setProject] = React.useState('');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchProjectContext, setSearchProjectContext] = React.useState(null);
  const [highlightedOption, setHighlightedOption] = React.useState(null);
  const [isSelecting, setIsSelecting] = useState(false);

  React.useEffect(() => {
    const newProjectTitleLookup = accountProjects.reduce((lookup, project) => {
      lookup[project.id] = project.name;
      return lookup;
    }, {});

    setProjectTitleLookup(newProjectTitleLookup);
  }, [accountProjects]);

  React.useEffect(() => {
    setSearchProjectContext(selectedProject)
  }, [selectedProject]);

  const fetchSearch = async (projectId) => {
    //console.log("Fetching records")
    let records;
    // if (projectId) {
    //   records = await retrieveAll({accountId: accountId, projectId: projectId })
    // } else {
    if (searchProjectContext) {
      records = await retrieveAll({accountId: accountId, projectId: searchProjectContext.id })
    } else {
      records = await retrieveAll({accountId: accountId })
    }
    // }
    //console.log("records: ", records)
    setSearchResults(records)
  }

  const handleFetchSearch = async () => {
    if (!running) {
      setRunning(true);
      try {
        await fetchSearch(selectedProjectId);
      } catch (error) {
        console.error("Error during fetchSearch:", error);
      } finally {
        setRunning(false);
      }
    }
  }

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const handleTypeAheadSearch = useCallback(debounce(async () => {
    if (!running && !isSelecting) {
      setRunning(true);
      try {
        const results = searchProjectContext
          ? await searchUsingText({ accountId, text: inputValue, project: searchProjectContext.id })
          : await searchUsingText({ accountId, text: inputValue });
        console.log("results", results);
        setSearchResults(results);
      } catch (error) {
        console.error("Error during search:", error);
      } finally {
        setRunning(false);
      }
    }
  }, 300), [inputValue, searchProjectContext, running, isSelecting]);

  const handleSelection = (newValue) => {
    setIsSelecting(true);
    setValue(newValue);
    if (mode === "addlink") {
      setUrlTitleAndLink(newValue);
    }
    setIsSelecting(false);
  };

  const redirectTo = (path) => {
    navigate(path);
  };

  const redirectToNotesPage = (accountId, projectId, noteId) => {
    redirectTo(navigationPaths.notes(accountId, projectId, noteId));
  };

  const redirectToNotesBinPage = (accountId, projectId, noteId) => {
    redirectTo(navigationPaths.notesBin(accountId, projectId, noteId));
  };

  const redirectToBoardsPage = (accountId, projectId, boardId) => {
    redirectTo(navigationPaths.boards(accountId, projectId, boardId));
  };

  const redirectToPersonasPage = (accountId, projectId, personaId) => {
    redirectTo(navigationPaths.personas(accountId, projectId, personaId));
  };

  const redirectToJourneyPage = (accountId, projectId, journeyId) => {
    redirectTo(navigationPaths.journeys(accountId, projectId, journeyId));
  };

  const redirectToFeaturesPage = (accountId, projectId) => {
    redirectTo(navigationPaths.features(accountId, projectId));
  };

  const redirectToPagesPage = (accountId, projectId, pageId = "") => {
    redirectTo(navigationPaths.pages(accountId, projectId, pageId));
  };

  const redirectToIaPage = (accountId, projectId, iaId) => {
    redirectTo(navigationPaths.ia(accountId, projectId, iaId));
  };

  const redirectToConnectionsPage = (accountId, projectId, connectionId) => {
    redirectTo(navigationPaths.connections(accountId, projectId, connectionId));
  };
  
  React.useEffect(() => {
    // Retrieve default search results TO DO: this isn't very performant
    handleFetchSearch();
  },[])

  React.useEffect(() => {
    if (selectedProjectId) {
      handleFetchSearch(selectedProjectId);
    }
  },[selectedProjectId])

  // useEffect to print searchResults
  useEffect(() => {
    //console.log("Search results: ", searchResults)
  }, [searchResults]);

  useEffect(() => {
    //console.log("Project updated: ", project)
  }, [project]);

  useEffect(() => {
    //console.log("Search term updated: ", searchTerm)
    if (inputValue > 2) {
      //handleTypeAheadSearch();
    }
  },[inputValue])

  React.useEffect(() => {
    // Only 1 selected item
    if (value) {
      if (mode !== "addlink") {
        if (value.type === "searchItem_note") {
          // As this is a note, redirect to the notes page
          if (value.bin) {
            redirectToNotesBinPage(accountId, value.project, value.noteId);
          } else {
            //console.log("Redirecting to notes page")
            redirectToNotesPage(accountId, value.project, value.noteId);
          }
        }
        if (value.type === "searchItem_tagBoard") {
          redirectToBoardsPage(accountId, value.project, value.boardId);
        }
        if (value.type === "searchItem_boardGroup") {
          redirectToBoardsPage(accountId, value.project, value.boardId);
        }
        if (value.type === "searchItem_tag") {
          redirectToBoardsPage(accountId, value.project, value.boardId);
        }
        if (value.type === "searchItem_persona") {
          redirectToPersonasPage(accountId, value.project, value.personaId);
        }
        if (value.type === "searchItem_journey") {
          redirectToJourneyPage(accountId, value.project, value.journeyId);
        }
        if (value.type === "searchItem_feature") {
          redirectToFeaturesPage(accountId, value.project);
        }
        if (value.type === "searchItem_sitemap") {
          redirectToIaPage(accountId, value.project, value.sitemapId);
        }
        if (value.type === "searchItem_page") {
          redirectToPagesPage(accountId, value.project, value.pageId);
        }
        if (value.type === "searchItem_pageset") {
          redirectToPagesPage(accountId, value.project, "");
        }
        if (value.type === "searchItem_connection") {
          redirectToConnectionsPage(accountId, value.project, value.connectionId);
        }
      } else {
        // Not header search
      }
    } 
  },[value])

  const setUrlTitleAndLink = (newValue) => {
    if (!newValue) return;
    if (newValue.title.startsWith("Add")) {
      // If the title starts with "Add", redirect to the URL
      setUrl("https://" + value.inputValue);
      setLinkTitle(value.inputValue);
    }
    if (newValue.type === "searchItem_note") {
      // As this is a note, redirect to the notes page
      if (newValue.bin) {
        setUrl(BASE_PRIVATEAPP_URL + accountId + '/' + newValue.project + `/bin?noteId=${newValue.noteId}`)
        setLinkTitle(newValue.title);
      } else {
        //console.log("Redirecting to notes page")
        setUrl(BASE_PRIVATEAPP_URL + accountId + '/' + newValue.project + `?noteId=${newValue.noteId}`)
        setLinkTitle(newValue.title);
      }
    }
    if (newValue.type === "searchItem_tagBoard") {
      setUrl(BASE_PRIVATEAPP_URL + accountId + '/' + newValue.project + `/tags/b/` + newValue.boardId);
      setLinkTitle(newValue.title);
    }
    if (newValue.type === "searchItem_boardGroup") {
      setUrl(BASE_PRIVATEAPP_URL + accountId + '/' + newValue.project + `/tags/b/` + newValue.boardId);
      setLinkTitle(newValue.title);
    }
    if (newValue.type === "searchItem_tag") {
      setUrl(BASE_PRIVATEAPP_URL + accountId + '/' + newValue.project + `/tags/b/` + newValue.boardId);
      setLinkTitle(newValue.title);
    }
    if (newValue.type === "searchItem_persona") {
      setUrl(BASE_PRIVATEAPP_URL + accountId + '/' + newValue.project + `/personas?personaId=` + newValue.personaId);
      setLinkTitle(newValue.title);
    }
    if (newValue.type === "searchItem_journey") {
      setUrl(BASE_PRIVATEAPP_URL + accountId + '/' + newValue.project + `/journeys?journeyId=` + newValue.journeyId);
      setLinkTitle(newValue.title);
    }
    if (newValue.type === "searchItem_feature") {
      setUrl(BASE_PRIVATEAPP_URL + accountId + '/' + newValue.project + `/features?featureId=` + newValue.featureId);
      setLinkTitle(newValue.title);
    }
    if (newValue.type === "searchItem_sitemap") {
      setUrl(BASE_PRIVATEAPP_URL + accountId + '/' + newValue.project + `/ia?iaId=` + newValue.sitemapId);
      setLinkTitle(newValue.title);
    }
    if (newValue.type === "searchItem_page") {
      setUrl(BASE_PRIVATEAPP_URL + accountId + '/' + newValue.project + `/pages?pageId=` + newValue.pageId);
      setLinkTitle(newValue.title);
    }
    if (newValue.type === "searchItem_pageset") {
      setUrl(BASE_PRIVATEAPP_URL + accountId + '/' + newValue.project + `/pages?pagesetId=` + newValue.pagesetId);
      setLinkTitle(newValue.title);
    }
    if (newValue.type === "searchItem_connection") {
      setUrl(BASE_PRIVATEAPP_URL + accountId + '/' + newValue.project + `/connections?connectionId=` + newValue.connectionId);
      setLinkTitle(newValue.title);
    }
    if (newValue.type === "url") {
      setUrl("https://" + newValue.title);
      setLinkTitle(newValue.title);
    }
  }

  let sortedSearchResults = [];

  // Define order of types to appear in search results
  const typeOrder = {
    'searchItem_note': 1,
    'searchItem_persona': 2,
    'searchItem_feature': 3,
    'searchItem_tag': 4,
    'searchItem_boardGroup': 5,
    'searchItem_tagBoard': 6,
    'searchItem_pageset': 7,
    'searchItem_page': 8,
    'searchItem_journey': 9,
    'searchItem_sitemap': 10,
    'searchItem_connection': 11
    //'searchItem_boardGroup': 3,
    // Add more types as needed
  };

  const typeMapping = {
    searchItem_tagBoard: 'Board',
    searchItem_boardGroup: 'Group',
    searchItem_tag: 'Tag',
    searchItem_note: 'Note',
    searchItem_persona: 'Persona',
    searchItem_feature: 'Feature',
    searchItem_pageset: 'Set',
    searchItem_page: 'Page',
    searchItem_journey: 'Journey',
    searchItem_sitemap: 'Navigation',
    searchItem_connection: 'Connection',
    // TO DO add more mappings as needed
  };
  
  if (Array.isArray(searchResults)) {
    sortedSearchResults = [...searchResults]
      .map(result => ({
        ...result,
        typeLabel: typeMapping[result.type] || "",
        projectName: projectTitleLookup[result.project]
      }))
      .sort((a, b) => {
        // First, sort by typeLabel
        const typeLabelComparison = a.typeLabel.localeCompare(b.typeLabel);
        if (typeLabelComparison !== 0) {
          return typeLabelComparison;
        }
        // If typeLabels are the same, sort by title
        return a.title.localeCompare(b.title);
      });
  }

  const filterOptionsMatchSorted = (sortedSearchResults, { inputValue }) => matchSorter(sortedSearchResults, inputValue, { keys: ['title'] });

  const handleRemoveProjectContext = () => {
    setSearchProjectContext(null)
  }

  return (
    <>
    { sortedSearchResults && 
    <Autocomplete
      freeSolo={ mode === "addlink" ? false : true }
      size={size}
      clearOnBlur
      sx={{
        width: mode === 'addlink' ? '100%' : ['100%', '520px'], 
        mt: [2, 0], 
        mb: [1, 0],
      }}
      slotProps={{
        listbox: {
          className: mode === 'addlink' ? 'add-link-search-combo-box' : ''
        }
      }}
      placeholder={ mode === "addlink" 
        ? "Search within smrt cookie" 
        : searchProjectContext 
          ? `Search within ${searchProjectContext.name}` 
          : "Search across project..." 
      }
      options={sortedSearchResults}
      groupBy={(option) => option.typeLabel}
      
      onFocus={handleFetchSearch}
      id="search-combo-box-with-hint"

      isOptionEqualToValue={(option, value) => option.id === value.id}

      startDecorator={
        <>
          <SearchRoundedIcon color="primary" />
          {searchProjectContext &&
            mode !== "pub" &&
            <Box sx={{ display: 'flex', alignItems: 'center' }}>              
              <Chip 
                onClick={() => handleRemoveProjectContext()} 
                endDecorator={
                  <IconButton ><ClearIcon /></IconButton>
                } 
                sx={{ mr: 1 }}
              >
                {searchProjectContext.name}
              </Chip>
            </Box>
          }
        </>
      }

      getOptionLabel={(option) => option.title}

      onChange={(event, newValue) => {
        handleSelection(newValue);
      }}

      onInputChange={(event, value, reason) => {
        if (reason === 'input' && value.length > 2) {
          handleTypeAheadSearch();
        }
        setInputValue(value);
      }}

      onHighlightChange={(event, newHighlightedOption) => {
        setHighlightedOption(newHighlightedOption);
      }
      }

      onKeyDown={(event) => {
        if (event.key === 'Enter' && highlightedOption) {
          event.preventDefault();
          handleSelection(highlightedOption);
        }
      }}
      onBlur={() => {
      }}
      inputValue={inputValue}
      filterOptions={filterOptionsMatchSorted}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.title, inputValue);
        const parts = parse(option.title, matches);
        return (
          option.inputValue !== "" &&
          <AutocompleteOption {...props} key={option.id} >
            <Typography level="inherit">
              {option.title === inputValue
                ? option.title
                : parts.map((part, index) => {
                  return (
                    <Typography
                      key={index}
                      {...(part.highlight && {
                        variant: 'soft',
                        color: 'primary',
                        fontWeight: 'lg',
                        px: '2px',
                      })}
                    >
                      {part.text}
                    </Typography>
                  )
                  })}
                  { option && option.projectName && option.projectName !== "" && <>&nbsp;<Typography sx={{ fontSize: 12, opacity: .7, ml: 1 }}>({ option.projectName })</Typography></>}
            </Typography>
          </AutocompleteOption>
        );
      }}
    />
    }
    {/* <Input
      size="sm"
      variant="outlined"
      placeholder="Search anything…"
      startDecorator={<SearchRoundedIcon color="primary" />}
      // endDecorator={
      //   <IconButton
      //     variant="outlined"
      //     color="neutral"
      //     sx={{ bgcolor: 'background.level1' }}
      //   >
      //     <Typography level="title-sm" textColor="text.icon">
      //       ⌘ K
      //     </Typography>
      //   </IconButton>
      // }
      sx={{
        alignSelf: 'center',
        minWidth: '400px',
        display: {
          xs: 'none',
          sm: 'flex',
        },
      }}
    />
      <IconButton
        size="sm"
        variant="outlined"
        color="neutral"
        sx={{ display: { xs: 'inline-flex', sm: 'none' }, alignSelf: 'center' }}
      >
      <SearchRoundedIcon />
    </IconButton> */}
    </>
  )
}
